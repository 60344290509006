import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { AffiliationCodeClass } from '../../../../../models';
import { ApiService } from '../../../../api/api.module';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
	id: number
}
@Component({
	selector: 'app-affiliation-code-user',
	templateUrl: './affiliation-code-user.component.html',
	styleUrls: ['./affiliation-code-user.component.scss']
})
export class AffiliationCodeUserComponent implements OnInit {

	@Input('id') set id(value) {
		this.load()
	}
	displayedColumns: string[] = ['id', 'Description', 'AffilateAt', 'remove'];
	@ViewChild(MatPaginator, {static:false}) paginator: MatPaginator;
	datasource: any = new MatTableDataSource<any>();

	onLoading: Boolean = false
	offset: number = -10
	data: Array<any> = []
	find: string = ""

	onPageChange(e: any) {
		if ((e.pageIndex + 1) * e.pageSize >= this.data.length) {
			this.load()
		}
	}

	onFind(e: any) {
		var value = e.srcElement.value
		this.offset = -10
		this.data = []
		this.find = value
		this.paginator.firstPage()
		this.load()
	}

	constructor(private api: ApiService, private route: ActivatedRoute, private router: Router, public snackBar: MatSnackBar, public dialog: MatDialog) { }
	load() {
		if (this.onLoading == false) {
			this.onLoading = true
		} else {
			return
		}
		this.offset += 10
		var id = Number(this.route.snapshot.paramMap.get('id'))
		this.api.getAffiliationCodeUsers(id, this.find, this.offset).subscribe((data) => {
			for (var i in data.body) {
				this.data.push(data.body[i])
			}
			this.onLoading = false
			this.datasource = new MatTableDataSource<any>(this.data);
			this.datasource.paginator = this.paginator;
		})
	}
	ngOnInit() {
		this.router.routeReuseStrategy.shouldReuseRoute = function () {
			return false;
		};
	}

	remove(uid: number) {
		var acid = Number(this.route.snapshot.paramMap.get('id'))
		this.api.removeAffiliationCode(acid, uid).subscribe((resp) => {
			if (resp.status == 200) {
				this.snackBar.open(`Le code d'affiliation a été retirer avec success a l'utilisateur ${uid}`, "ok", { duration: 5000 })
			}
		}, (err) => {
			this.snackBar.open(`Une Erreur ces produite lors du retrait ${err.status} error: ${JSON.stringify(err.error)}`, "ok", { duration: 5000 })
			console.log(err)
		})
	}

	add() {
		const dialogRef = this.dialog.open(DialogAffiliationCodeUserAdd, {
			width: '250px',
			data: { id: null }
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log("result", result);
			if (result == null || result == 0) {
				return
			}
			var acid = Number(this.route.snapshot.paramMap.get('id'))
			this.api.activeAffiliationCode(acid, result).subscribe((resp) => {
				if (resp.status == 200) {
					this.snackBar.open(`Le code d'affiliation a été ajouter avec success a l'utilisateur ${result}`, "ok", { duration: 5000 })
				}
			}, (err) => {
				this.snackBar.open(`Une Erreur ces produite lors de l'ajout ${err.status} error: ${JSON.stringify(err.error)}`, "ok", { duration: 5000 })
				console.log(err)
			})
		});
	}
}


@Component({
	selector: 'dialog-overview-example-dialog',
	templateUrl: 'affiliation-code-user-add.component.html',
})
export class DialogAffiliationCodeUserAdd {

	constructor(
		public dialogRef: MatDialogRef<DialogAffiliationCodeUserAdd>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

	onNoClick(): void {
		this.dialogRef.close();
	}

}