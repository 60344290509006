import { Component, OnInit } from '@angular/core';
import {ExtractParams} from '../../../models'

import { ApiService } from '../../api/api.module';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
	selector: 'app-extract-params-one',
	templateUrl: './extract-params-one.component.html',
	styleUrls: ['./extract-params-one.component.scss']
})
export class ExtractParamsOneComponent implements OnInit {
	params:ExtractParams = new ExtractParams({
		user_id: 0,
		map:{
		},
		exclude: []
	})
	tmp_map_name = ''

	constructor(private api:ApiService, private route: ActivatedRoute, private router: Router) { }

	loadParams(id: number){
		this.api.getExtractParamsOne(id).subscribe(data => {
			this.params = new ExtractParams(data.body)
		})
	}

	ngOnInit() {
		var id = this.route.snapshot.paramMap.get('id')
		if (id == "new"){
			console.log("new params")
			return
		} else {
			this.loadParams(Number(id))
		}
	}

	removeMap(key:string) {
		this.params.removeMap(key)
	}

	removeExclude(index:number) {
		this.params.removeExclude(index)
	}

	addExclude(){
		this.params.addExclude()
	}

	getKeysMaps() {
		return Object.keys(this.params.map)
	}

	addMap(){
		this.params.addMap(this.tmp_map_name)
	}

	save() {
		console.log(this.params)
		this.api
		.postExtractParams(this.params).subscribe(data =>{
			console.log(data.body)
			this.params = new ExtractParams(data.body)
		})
	}
}
