import { Component, OnInit, Input, SimpleChange, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../api/api.module';
import { Report } from '../../../models';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material';
import { BottomSheetExport } from './bottom-sheet-export';


let downloadFile = function (filename, data) {
	var link = document.createElement('a')

	let blob = data
	link.href = window.URL.createObjectURL(blob)
	link.download = filename

	document.body.appendChild(link)
	link.click()

	setTimeout(function () {
		document.body.removeChild(link)
		window.URL.revokeObjectURL(link.href)
	}, 100)
}



@Component({
	selector: 'app-reports-list',
	templateUrl: './reports-list.component.html',
	styleUrls: ['./reports-list.component.scss']
})
export class ReportsListComponent implements OnInit {
	displayedColumns: string[] = ['Aperçu', 'Informations', 'Modèle', 'export', 'Dates',];
	reportDatasource: any = new MatTableDataSource<Report>([]);
	@ViewChild(MatPaginator, {static:false}) paginator: MatPaginator;

	@Input('user-id') userId: number = 0;
	@Input('find') find = "";
	@Input('pc') pc = "";
	@Input('infos') infos = true;
	@Input('filter') filter = "";
	currentlyDisabled: string;
	reports: Array<Report> = []
	pages = -1
	onLoading = false

	constructor(private api: ApiService, private bottomSheet: MatBottomSheet) {
	}

	load() {
		if (this.onLoading == false) {
			this.onLoading = true
		} else {
			return
		}
		this.pages += 1
		this.api.getReports(this.find, this.pc, this.userId, this.pages).subscribe((data) => {
			for (var i in data.body) {
				this.reports.push(new Report(data.body[i]))
			}
			this.onLoading = false
			this.reportDatasource = new MatTableDataSource<Report>(this.reports);
			this.reportDatasource.paginator = this.paginator;
		})
	}

	open(state: string, proov_code: string) {
		this.api.getReportPdf(proov_code, state).then((data) => {
			downloadFile(`${proov_code}_${state}.pdf`, data)
		})
	}

	onFind(e: any, mode: string, filter:string) {
		var value = e.srcElement.value
		this.pages = -1
		this.reports = []

		if (filter == 'all') {
			this.pc = ""
			this.find = value
		} else if (filter  == 'pc') {
			this.find = ""
			this.pc = value
		}

		this.paginator.firstPage()
		this.load()
	}

	ngOnInit() {
		this.load()
	}

	onPageChange(e: any) {
		if ((e.pageIndex + 1) * e.pageSize >= this.reports.length) {
			this.load()
		}
	}

	copyJson(report: Report) {
		this.api.getReport(report.proov_code).subscribe((res) => {
			var data = JSON.stringify(res.body)
			this.bottomSheet.open(BottomSheetExport, { data: [data] });
			// this.copy(data)
		})
	}
}