import { Translation } from "../common/translation"
export class Process {
	id: number
	createdAt: string
	updatedAt: string

	title: number
	cached_id: number
	number: number
	orientation: string
	category_id: string
	name: string

	_title: Translation

	public get titleTr() {
		if (this._title != null && this._title != undefined) {

			return this._title.fr
		}
		return ""
	}
	public constructor(init?: Partial<Process>) {
		Object.assign(this, init);
	}
} 
