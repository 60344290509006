import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-process-category-selector',
	templateUrl: './process-category-selector.component.html',
	styleUrls: ['./process-category-selector.component.css']
})
export class ProcessCategorySelectorComponent implements OnInit {
	parent: string = "item"
	isSubcategory: boolean = false
	constructor(private router: Router, private route: ActivatedRoute) { }

	ngOnInit() {
		var tmp = this.route.snapshot.paramMap.get('category')
		if (tmp != null) {
			this.parent = tmp
			this.isSubcategory = true
		}
		console.log("hello")
	}

	onSelect(category) {
		if (this.isSubcategory == false) {
			this.router.navigate([`/process/${category.value}`]);
		} else {
			this.router.navigate([`/process/${this.parent}/${category.value}`]);
		}
	}

}
