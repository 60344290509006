import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User, Right } from '../../models'
import { AdminPermission } from '../api/admin-permission.module'
// import { ConsoleReporter } from 'jasmine';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private router: Router) { }
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Promise<boolean> | boolean {
		if (localStorage.getItem('currentUser')) {
			try {
				var u = new User(JSON.parse(localStorage.getItem('currentUser')))
				return true
			} catch {
				return false
			}
		}
		this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
		return false;
	}
}

@Injectable()
export class ViewUserGuard implements CanActivate {
	constructor(private router: Router) { }
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Promise<boolean> | boolean {
		if (localStorage.getItem('currentUser')) {
			return true;
		}
		this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
		return false;
	}
}