export class ExtractParams {
	id: number
	name: string
	delimiter:string
	map: object
	exclude: string[]
	lang: string
	date_format: string
	user_id: number

	public constructor(init?:Partial<ExtractParams>) {
		Object.assign(this, init);
	}

	public addExclude(){
		this.exclude.push("")
	}

	public removeExclude(index:number) {
		this.exclude.splice(index, 1);
	}

	public removeMap(key:string) {
		delete this.map[key]
	}

	public addMap(key:string){
		this.map[key] = ""
	}

}