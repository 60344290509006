import { Component, OnInit, Output, Input, EventEmitter, Inject } from '@angular/core';
import { ApiService } from '../../api/api.module';
import { Process} from '../../../models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
	selector: 'app-process-selector',
	templateUrl: './process-selector.component.html',
	styleUrls: ['./process-selector.component.css']
})
export class ProcessSelectorComponent implements OnInit {

	parentCategory: string;
	@Output('onSelect') _event = new EventEmitter<Process>();
	@Input('selected') _selected: Process;
	@Input('canEdit') canEdit: boolean = true;
	search: string = ""

	@Input('category')
	set parent(value: string) {
		this.process = []
		this.parentCategory = value
		this.load()
	}

	@Input('categoryId') categoryId: number;

	loading: boolean = false;
	process: Array<Process>
	selected: number = -1;
	constructor(private api: ApiService, public dialog: MatDialog) { }

	ngOnInit() {
	}

	get selectedId() {
		if (this._selected == null && this._selected == undefined) {
			return -1
		}
		return this._selected.id
	}

	load() {
		if (this.parentCategory == null || this.parentCategory == undefined) {
			return
		}
		this.loading = true


		this.api.getAllProcessByCategory(this.parentCategory).subscribe((resp) => {
			for (var i in resp.body) {
				this.process.push(new Process(resp.body[i]))
				console.log(resp.body[i])
			}
			this.loading = false
		}, (err) => {
			console.log(err)
			this.loading = false
		})
	}

	onSelect(cat: Process) {
		this.selected = cat.id
		this._event.emit(cat)
	}

	drop(event: CdkDragDrop<any[]>) {
		if (!this.canEdit) {
			return
		}
		if (this.search.length > 0) {
			return
		}
		moveItemInArray(this.process, event.previousIndex, event.currentIndex);
		var toSend = []
		for (let i in this.process) {
			this.process[i].number = 0 + Number(i)
			toSend.push({ id: this.process[i].id, position: this.process[i].number })
		}
		// this.api.postCategoryOrder(toSend).subscribe(rep => {
		// 	console.log(rep)
		// })
	}

	openEdit(process): void {
		const dialogRef = this.dialog.open(ProcessEditorModal, {
			data: process, 
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
			console.log(result)
			if (result != undefined) {
				// this.api.postCategory(result).subscribe(res => {
				// 	// this.loadCategory(cat.type)
				// })
			}
		});
	}

	onCreate() {
		this.openEdit({
			id: 0,
			category_id: this.categoryId,
		})
	}

}



@Component({
	selector: 'process-edit-dialog',
	templateUrl: 'process-edit-dialog.html',
})
export class ProcessEditorModal {
	selected: Process;
	constructor(
		public dialogRef: MatDialogRef<ProcessEditorModal>,
		@Inject(MAT_DIALOG_DATA) public data: Process) {
		this.selected = data
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

}
