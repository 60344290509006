// [
//     {
//         "id": 163203,
//         "state": "pending_second",
//         "proov_code": "F2131F",
//         "preview_picture": 2828451,
//         "dropoff": true,
//         "full_dropoff": false,
//         "auto_restart": false,
//         "title": 923,
//         "description": 924,
//         "meta": [
//             {
//                 "_title": {
//                     "id": 974,
//                     "fr": "Signataire",
//                     "en": "Signatory",
//                     "es": "Signatario",
//                     "nl": "ondertekenaar",
//                     "pt": "Signatário",
//                     "de": "Unterzeichner",
//                     "it": "Firmatario",
//                     "pl": "Sygnatariusz"
//                 },
//                 "value": "",
//                 "title": 974
//             },
//             {
//                 "_title": {
//                     "id": 2594,
//                     "fr": "Blue Valet",
//                     "en": "Blue Valet",
//                     "es": "Valet azul",
//                     "nl": "Blue Valet",
//                     "pt": "Blue Valet",
//                     "de": "Blauer Kammerdiener",
//                     "it": "Blue Valet",
//                     "pl": "Blue Valet"
//                 },
//                 "value": "Enzo",
//                 "title": 2594
//             },
//             {
//                 "_title": {
//                     "id": 5,
//                     "fr": "Véhicule",
//                     "en": "Vehicle",
//                     "es": "Vehículo",
//                     "nl": "Voertuig",
//                     "pt": "Veículo",
//                     "de": "Fahrzeug",
//                     "it": "Veicolo",
//                     "pl": "Pojazd"
//                 },
//                 "value": "EJ-961-KK",
//                 "title": 5
//             },
//             {
//                 "_title": {
//                     "id": 671,
//                     "fr": "Client",
//                     "en": "Customer",
//                     "es": "Cliente",
//                     "nl": "Klant",
//                     "pt": "Cliente",
//                     "de": "Kunde",
//                     "it": "Cliente",
//                     "pl": "Klient"
//                 },
//                 "value": "Laurent Bozzoni",
//                 "title": 671
//             }
//         ],
//         "finished_at": "2018-06-03T07:25:05.651+00:00",
//         "dropoff_finished_at": null,
//         "owner": 765,
//         "updatedAt": "2018-06-03T07:25:06.184+00:00",
//         "identifer_items": {
//             "value": "EJ-961-KK",
//             "name": "register",
//             "title": 105,
//             "_title": {
//                 "id": 105,
//                 "fr": "Immatriculation",
//                 "en": "Vehicle Licence Plate",
//                 "es": "Placa de matrícula de vehículos",
//                 "nl": "Voertuig kentekenplaat",
//                 "pt": "Placa de licença de veículo",
//                 "de": "Kfz-Kennzeichen",
//                 "it": "Targa del veicolo",
//                 "pl": "Tablica rejestracyjna pojazdu"
//             }
//         },
//         "_description": {
//             "id": 924,
//             "fr": "La preuve de l'état de votre voiture",
//             "en": "The car condition report of your vehicle",
//             "es": "El informe de condición del automóvil de su vehículo",
//             "nl": "Het autokwaliteitsrapport van uw voertuig",
//             "pt": "O relatório de condição do carro do seu veículo",
//             "de": "Der Fahrzeugzustandsbericht Ihres Fahrzeugs",
//             "it": "Il rapporto sulla condizione dell'auto del tuo veicolo",
//             "pl": "Raport o stanie samochodu twojego pojazdu"
//         },
//         "_title": {
//             "id": 923,
//             "fr": "BLUE VALET - ETAT DES LIEUX DU VÉHICULE",
//             "en": "BLUE VALET - VEHICLE CONDITION REPORT",
//             "es": "BLUE VALET - INFORME DE ESTADO DEL VEHÍCULO",
//             "nl": "BLUE VALET - RAPPORT VOOR VOERTUIGOMSTANDIGHEID",
//             "pt": "BLUE VALET - RELATÓRIO DE CONDIÇÃO DO VEÍCULO",
//             "de": "BLUE VALET - FAHRZEUGZUSTANDSBERICHT",
//             "it": "BLUE VALET - REPORT SULLE CONDIZIONI DEL VEICOLO",
//             "pl": "NIEBIESKI VALET - RAPORT O WARUNKACH POJAZDÓW"
//         },
//         "_preview_picture": {
//             "id": 2828451,
//             "_picture": {
//                 "path": "image/F2131F/1150e0f122339d083111b4e88392d3b8.jpeg"
//             }
//         }
//     }
// ]

import {Translation} from '../common/translation'

export class ReportMeta {
	_title:Translation
	value: string

	getTitle(): string {
		return this._title.fr
	}

	public constructor(init?:Partial<ReportMeta>) {
		Object.assign(this, init);
	}
}

export class Report {
	id: number
	state: string
	proov_code: string
	preview_picture: number
	dropoff: boolean
	full_dropoff: boolean
	auto_restart: boolean
	title: number
	_title:Translation

	description: number
	_description: Translation
	
	meta: Array<ReportMeta>

	finished_at:string
	dropoff_finished_at: string
	updatedAt:string
	owner:number
	identifer_items:any
	_preview_picture:any
	getTitle(): string {
		return this._title.fr
	}
	getPreviewPath(){
		if (this._preview_picture != null && this._preview_picture != undefined && this._preview_picture._picture != null && this._preview_picture._picture != undefined) {
			return this._preview_picture._picture.path
		}
		return ""
	}
	public constructor(init?:Partial<Report>) {
		Object.assign(this, init);
		for(var i in init.meta){
			this.meta[i] = new ReportMeta(init.meta[i])
		}
	}
}