import { Component, OnInit, Input } from '@angular/core';
import { AffiliationCodeClass, AutoWebhooks } from '../../../../../models';
import { MatTableDataSource } from '@angular/material/table';
@Component({
	selector: 'app-affiliation-code-auto-webhooks',
	templateUrl: './affiliation-code-auto-webhooks.component.html',
	styleUrls: ['./affiliation-code-auto-webhooks.component.css']
})
export class AffiliationCodeAutoWebhooksComponent implements OnInit {

	displayedColumns: string[] = ['url', 'secret_key', 'local', 'template_id', 'owner', 'init', 'update', 'first_finished', 'second_finished', 'RemoveOnCleanup', 'delete'];
	datasource: MatTableDataSource<AutoWebhooks>

	_params: Array<AutoWebhooks>

	@Input('params') set params(value) {
		console.log("set")
		this._params = []
		if (value == null || value == undefined) {
			this.datasource = new MatTableDataSource([])
			return
		}
		this._params = value.auto_webhooks
		this.datasource = new MatTableDataSource(this._params)
	}

	constructor() {
		this._params = []
	}

	ngOnInit() { }

	addRow() {
		this._params.push(new AutoWebhooks())
		this.datasource.data = this._params
	}

	removeRow(index: number) {
		this._params.splice(index, 1)
		this.datasource.data = this._params
	}
}
