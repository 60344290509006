import { Component, OnInit, Inject } from '@angular/core';

@Component({
	selector: 'app-category',
	templateUrl: './category.component.html',
	styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

	selected: string = 'item'
	constructor() {

	}

	ngOnInit() {
	}
}

