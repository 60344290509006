import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api/api.module';
import { MatSnackBar } from '@angular/material';

@Component({
	selector: 'app-extract-csv',
	templateUrl: './extract-csv.component.html',
	styleUrls: ['./extract-csv.component.css']
})
export class ExtractCsvComponent implements OnInit {
	baseUrl = `http://wprv-admin-extract.eu-west-1.elasticbeanstalk.com/production`

	userId = 0
	templateId = 0
	find = ""
	startDate = ""
	endDate = ""
	fileType = "csv"
	constructor(
		private api: ApiService, 
		public snackBar: MatSnackBar,
	) { }

	// 
	setUserId(value: string) {}

	startDateChange(event: any) {
		this.startDate = event.value.toISOString()
	}

	endDateChange(event: any) {
		this.endDate = event.value.toISOString()
	}

	openSnackBar(message: string, action: string) {
		this.snackBar.open(message, action, {
			duration: 5000,
		});
	}

	genereateUrl() {
		var params: any
		params = {
			uid: this.userId,
			template_id: this.templateId,
			access_token: JSON.parse(localStorage.getItem('currentUser') as any).access_token,
			user_mail: JSON.parse(localStorage.getItem('currentUser') as any).email,
			find: this.find,
			filetype: this.fileType,
		}

		if (this.startDate != null && this.startDate != "") {
			params["created_at"] = this.startDate
		}

		if (this.endDate != null && this.endDate != "") {
			params["end_date"] = this.endDate
		}

		this.api.extractReport(params).subscribe(rep => {
			console.log(rep)
			if (rep.status == 200) {
				this.openSnackBar("Vous allez recevoir un mail d'ici quelques minutes", "ok")
			} else {
				this.openSnackBar("Une erreur s'est produite lors de l'export'", "ok")
			}
		})
	}

	ngOnInit() {
	}

}
