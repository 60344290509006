import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../api/api.module';
import { Category } from '../../../models';

@Component({
	selector: 'app-category-selector',
	templateUrl: './category-selector.component.html',
	styleUrls: ['./category-selector.component.css']
})
export class CategorySelectorComponent implements OnInit {
	private parentCategory: string;
	loading: boolean = false
	@Output('onSelect') _event = new EventEmitter<Category>();

	@Input('parent')
	set parent(value: string) {
		this.category = []
		this.parentCategory = value
		console.log(this.parentCategory)
		this.load()
	}

	category: Array<Category> = [];

	constructor(private api: ApiService) { }

	ngOnInit() {
	}

	load() {
		if (this.parentCategory == null || this.parentCategory == undefined) {
			return
		}

		this.api.getAllCategory(this.parentCategory).subscribe((resp) => {
			for (var i in resp.body) {
				this.category.push(new Category(resp.body[i]))
			}
			this.loading = false
		}, (err) => {
			console.log(err)
			this.loading = false
		})
	}

	onSelect(cat: Category) {
		this._event.emit(cat)
	}
}

