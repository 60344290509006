import { Component, OnInit, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ApiService } from '../api/api.module';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationEditSelectionListComponent } from '../component/translation-edit-selection/translation-edit-selection.component';
import { TranslationSelectionListModalComponent } from '../component/translation-selection-list-modal/translation-selection-list-modal.component';
import { Fields, Translation } from '../../models';
import { MatSnackBar } from '@angular/material';
@Component({
	selector: 'app-fields',
	templateUrl: './fields.component.html',
	styleUrls: ['./fields.component.scss']
})
export class FieldsComponent implements OnInit {

	constructor(
		private api: ApiService,
		public dialog: MatDialog) { }
	master = ""
	subscategory = null
	subscategory_selected = ""
	find = ""
	fields = []
	pages = -1

	ngOnInit() {
	}
	onMasteChange(){
		this.subscategory_selected = ""
		this.api.getAllCategory(this.master).subscribe(data =>{

			this.subscategory = data.body
		})
	}
	onFind(value){
		this.find = value
		this.onSubsChange()
	}
	loadField(){
		this.pages += 1
		this.api.getAllField(Number(this.subscategory_selected), this.find ,this.pages).subscribe(data =>{
			console.log(data.body)
			this.fields = [...this.fields, ...data.body]
		})
	}
	onSubsChange(){
		this.fields = []
		this.pages = -1
		this.loadField()
	}

	onScroll(){
		this.loadField()
	}

	openFieldCreator(category_id){
		var fields = new Fields()
		fields.category_id = Number(category_id)
		fields._create = true
		this.dialog.open(FieldEditDialog, {
			data: fields
		});
	}

	getField(field:Fields){
		field._create = false
		console.log(field)
		this.dialog.open(FieldEditDialog, {
			data: field
		});
	}
}

@Component({
	selector: 'field_creator',
	templateUrl: 'field_creator.html',
	styleUrls: ['./field_creator.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class FieldEditDialog {
	constructor(
		public dialogRef: MatDialogRef<FieldEditDialog>,
		@Inject(MAT_DIALOG_DATA) public field: Fields,
		public dialog: MatDialog,
		private api: ApiService,
		public snackBar: MatSnackBar,
		private cdr: ChangeDetectorRef
	){
	}

	translations: Translation[] = []
	page = -1
	haveNext = true

	ngOnInit() {
		this.page += 1

		this.api.getTranslations("", this.page, false).subscribe(rep => {
			rep.body.forEach(element => {
				this.translations.push(element)
			});
			this.haveNext = rep.body.length == 50 ? true : false
		})
	}

	openModal() {
		this.cdr.detach();
		const dialogRef = this.dialog.open(TranslationEditSelectionListComponent, {
			maxWidth: '90vw',
			maxHeight: '90vh',
			height: '90%',
			width: '90%',
			data: this.translations
		});

		dialogRef.afterClosed().subscribe(result => {
			this.cdr.reattach()
			console.log('The french Translation list dialog is closed');
			console.log(result)
			if (result != undefined) {
				this.field.title = result.id;
				this.field._title = result;
			}
		});
	}

	openTradModal(field:Fields) {
		this.cdr.detach();
		const dialogRef = this.dialog.open(TranslationSelectionListModalComponent, {
			maxWidth: '90vw',
			maxHeight: '90vh',
			height: '90%',
			width: '90%',
			data: {
				field: field, 
				translations: this.translations
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			this.cdr.reattach()
			console.log('The Translations list dialog is closed');
			console.log(result)
			if (result != undefined) {
				this.field.parameters._list = result;
				this.field.parameters.list = result.map(r => r.id);
			}
		});
	}

	openSnackBar(message: string, action: string) {
		this.snackBar.open(message, action, {
			duration: 5000,
		});	
	}

	ok() {
		console.log(this.field)
		if (this.field.name == undefined || this.field.name.length == 0) {
			return window.alert("Remplir le champs 'Nom'")
		}
		if (this.field.title == undefined || this.field.title < 1) {
			return window.alert("Selectionner un titre")
		}
		if (this.field.type == 'webview' && (this.field.parameters == undefined || this.field.parameters.webview == undefined || this.field.parameters.webview.url.length == 0 || !this.field.parameters.webview.url.startsWith('http'))) {
			return window.alert("Rentrer un URL valide")
		}
		if (this.field.type == 'vin_scan') {
			if (this.field.parameters.is_control_key && (this.field.parameters.control_key == undefined || this.field.parameters.control_key.length == 0)) {
				return window.alert("Définir le nom de la control_key")
			}
			this.field.parameters.webview.url = "https://d083vmec1f.execute-api.eu-west-1.amazonaws.com/test/checkvin?last_name=customer_name&first_name=customer_first_name&mail=customer_email&brand=brand&model=model_expert&vin_status=vin_status"
		}
		if (this.field.type == 'list') {
			if (this.field.parameters.is_multiple) {
				//passer a true si 10354 est present ou faire bouton de "Option Tous"
				// this.field.parameters.can_select_all = true
				const index: number = this.field.parameters.list.indexOf(10354)
				if (index === -1 && this.field.parameters.can_select_all == true) {
					this.field.parameters.list.unshift(10354)
				} else if (index > 0 && this.field.parameters.can_select_all == true ) {
					this.field.parameters.list.splice(index, 1)
					this.field.parameters.list.unshift(10354)
				}
			} else if (!this.field.parameters.is_multiple) {
				this.field.parameters.can_select_all = false
			}
		}
		this.api.postFields(this.field).subscribe(rep => {
			if (rep.status == 200) {
				this.field._create ? this.openSnackBar("Le champs a ete crée avec succes", "ok") : this.openSnackBar("Le champs a bien été mis à jour", "ok")
				this.onNoClick()
			}
			else {
				this.field._create ? this.openSnackBar("Une erreur s'est produite lors de la creation du champs", "ok") : this.openSnackBar("Une erreur s'est produite lors de la mise à jour du champs", "ok")
				console.log(rep)
			}
		})
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
}
