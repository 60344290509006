import {Translation} from '../common/translation'

export class Fields {
    name : string 
    title : number 
    type : string 
    value: string
    parameters: FieldParameters = new FieldParameters() 
    identifier : Boolean 
    forced : Boolean 
    required : Boolean 
    section : string 
    category_id : number 
    id : number 
    createdAt: Date 
    updatedAt: Date 
    referenced : Boolean
    _title: string
    _create: boolean

    public constructor(init?:Partial<Fields>) {
        Object.assign(this, init);
        this.parameters = new FieldParameters()
        if (init != undefined && init != null) {
            this.parameters = new FieldParameters(init.parameters)
        }
	}
}

export class FieldParameters {
	list: Array<number> = []
    _list: Array<Translation> = []
    is_multiple: boolean
    webview: FieldWebview = new FieldWebview()
    is_control_key: boolean
    control_key: string
    can_select_all: boolean

    public constructor(init?:Partial<FieldParameters>) {
        Object.assign(this, init);
        if (init != undefined && init != null) {
            for(let i in this._list) {
                this._list[i] = new Translation(init._list[i])
            }
            this.webview = new FieldWebview(init.webview)
        }
	}
}

export class FieldWebview {
    url: string
    enable_geoloc: boolean

    public constructor(init?:Partial<FieldWebview>) {
		Object.assign(this, init);
	}
}