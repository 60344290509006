import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, RouterState, ActivatedRoute } from '@angular/router';
import {MediaMatcher} from '@angular/cdk/layout';
import { Location } from '@angular/common';
@Component({
	selector: 'app-top-bar',
	templateUrl: './top-bar.component.html',
	styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit,OnDestroy  {
	mobileQuery: MediaQueryList;
	private _mobileQueryListener: () => void;
	forceOpen:boolean = false
	
	constructor(private router: ActivatedRoute,private location: Location, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) { 
		this.mobileQuery = media.matchMedia('(max-width: 812px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		this.mobileQuery.addListener(this._mobileQueryListener);
	}

	ngOnInit() {
	}

	colapse() {
		this.forceOpen = !this.forceOpen
	}
	ngOnDestroy(): void {
		this.mobileQuery.removeListener(this._mobileQueryListener);
	}
	onMenuClicked() {
		this.forceOpen = false
	}
}
