import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../../api/api.module';
import { Translation, Fields } from '../../../models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
@Component({
	selector: 'app-translation-selection-list',
	templateUrl: './translation-selection-list.component.html',
	styleUrls: ['./translation-selection-list.component.css', '../translation-card/translation-card.component.scss']
})
export class TranslationSelectionListComponent implements OnInit {
	constructor(
		private api: ApiService,
		public dialogRef: MatDialogRef<TranslationSelectionListComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,

		// @Inject(MAT_DIALOG_DATA) private field: Fields,
		// @Inject(MAT_DIALOG_DATA) private translations: Translation[],

	) { }

	translations: Translation[] = []
	field: Fields
	translationsSelected: Translation[] = []
	page = -1
	search = ""
	haveNext = false
	loading = false

	ngOnInit() {
		this.translations = this.data.translations
		this.field = this.data.field
		console.log(this.data)
		if (this.field.parameters.list != undefined) {
			this.translationsSelected = this.data.field.parameters._list
		}
	}

	loadNext() {
		this.page += 1
		this.loading = true
		this.api.getTranslations(this.search, this.page, false).subscribe(rep => {
			for(var j = 0; j < rep.body.length; j++) {
				var start = false;
				for (var i = 0; i < this.translationsSelected.length; i++) {
					if (rep.body[j].id == this.translationsSelected[i].id) {
						start = true
					}
				}
				if (start != true) {
					this.translations.push(rep.body[j])
				}
			}
			this.loading = false
			this.haveNext = rep.body.length == 50 ? true : false
		}, () => {
			this.loading = false
		})
	}

	onInputChange() {
		this.page = -1
		this.translations = []
		this.loadNext()
	}

	drop(event: CdkDragDrop<Translation[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			transferArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex);
		}
	}

	onClose() {
		this.dialogRef.close(this.translationsSelected)
	}

	add() {
		this.translations.unshift(new Translation())
	}

	postTranslation(tr :Translation) {
		console.log(tr)
		this.api.postTranslation(tr).subscribe(rep => {
			Object.assign(tr, rep.body);
			console.log(rep)
		})
	}
}
