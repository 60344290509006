import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AffiliationCodeClass, AffiliationCodeTeam, AffiliationCodeParameters } from '../../../../../models';
import { MatTableDataSource } from '@angular/material/table';
@Component({
	selector: 'app-affiliation-code-super-team',
	templateUrl: './affiliation-code-super-team.component.html',
	styleUrls: ['./affiliation-code-super-team.component.css']
})
export class AffiliationCodeSuperTeamComponent implements OnInit {

	displayedColumns: string[] = ['UserId', 'RemoveOnCleanup'];
	datasource: MatTableDataSource<AffiliationCodeTeam>

	_params: AffiliationCodeParameters

	@Input('params') set params(value) {
		console.log("set")
		this._params = value
		this.datasource = new MatTableDataSource([value.auto_team])
	}
	constructor(private cdRef: ChangeDetectorRef) { }

	ngOnInit() {
	}

}
