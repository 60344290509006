import { Subscription } from './right'

export class User {
	id: number
	username: string
	email: string
	type: string
	enterprise_id: number
	picture: number
	cell: string
	address: string
	first_name: string
	last_name: string
	company: string
	email_template: number
	image_report: number
	email_certified: boolean
	cell_certified: boolean
	enterprise: boolean
	admin: boolean
	general_template: boolean
	primary_color: string
	pdf_header_text_color: string
	fleet: boolean
	access_token: string
	pack: number[]
	lang: string
	extra: any
	updatedAt: string
	createdAt: string
	subscription: Subscription = new Subscription()
	auth_id: number

	public constructor(init?: Partial<User>) {
		Object.assign(this, init);
		this.pack = []
		this.subscription = new Subscription()
		if (this.subscription != undefined || this.subscription != null) {
			this.subscription = new Subscription(this.subscription)
		}
		
		for (var i in init.pack) {
			this.pack.push(init.pack[i])
		}
	}

	public isAdmin(): boolean {
		return this.admin
	}
}