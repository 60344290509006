import { Component, OnInit,ViewChild } from '@angular/core';
import { ApiService } from '../../api/api.module';
import { User, Subscription } from '../../../models';

import {MatPaginator, MatTableDataSource} from '@angular/material';
@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss']
})


export class UserComponent implements OnInit {
	displayedColumns: string[] = ['Id', 'Name', 'Date', 'Statut', 'Pack', 'Used / Max'];
	userDatasource:any = new MatTableDataSource<User>([]);
	@ViewChild(MatPaginator, {static:false}) paginator: MatPaginator;
	users:Array<User>= []
	pages = -1
	find = ""
	
	constructor(
		private api: ApiService,
	) {}

	async getUsersSubscription(users:Array<User>, ids: Array<number>) {
		var newList: Array<number>
		newList = ids
		if (this.pages > 0) {
			newList = ids.slice(40*this.pages)
		}

		const t = await this.api.getUsersSubscription(newList.toString()).toPromise();

		var subscriptions: Array<Subscription>
		subscriptions = t.body 

		for (var i in subscriptions) {
			if (subscriptions[i] != null) {
				for (var j in users) {
					if (subscriptions[i].user_id == users[j].id) {
						users[j].subscription = new Subscription(subscriptions[i])
						break
					}
				}
			}
		}
	}

	onPageChange(e:any){
		if ((e.pageIndex+1) * e.pageSize >= this.users.length) {
			this.load()
		}
	}

	load() {
		this.pages += 1

		this.api.getUsers(this.find, this.pages).subscribe((data) =>{
			for (var i in data.body) {
				this.users.push(data.body[i])
			}
			
			var ids: Array<number> = []
			for (var i in this.users) {
				ids[i] = this.users[i].id
			}
			
			this.getUsersSubscription(this.users, ids)

			this.userDatasource = new MatTableDataSource<User>(this.users);
			this.userDatasource.paginator = this.paginator;
		})

	}

	onInputChange(e:any){
		this.onFind(e.srcElement.value)
	}

	onFind(value:string) {
		this.pages = -1
		this.paginator.firstPage()
		this.users = []
		this.find = value
		this.load()
	}
	
	ngOnInit() {
		this.load()
	}

}