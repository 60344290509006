import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api/api.module';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	error:string
	returnUrl:string
	constructor(private api: ApiService,
		private route: ActivatedRoute,
		private router: Router) { 
		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
	}


	login(log:string, pass:string){
		this.api.postAuth({'identifier': log, 'password': pass}).subscribe(access_token => {
			let tokenStr = JSON.stringify(access_token)

			if (tokenStr.length == 2 || access_token.body == "") {
				this.error = "Votre adresse mail ou mot de passe est incorrect"
				return
			}

			let user = {}; 
			user['email'] = log;
			user['access_token'] = (access_token.body != undefined ? access_token.body : access_token)

			localStorage.setItem('currentUser', JSON.stringify(user))
			this.api.refreshOption()
			this.router.navigateByUrl(this.returnUrl);
		}, error =>{
			console.log(this.error)
			this.error = error.error.errors[0].message
		})
	}

	ngOnInit() {

	}

}
