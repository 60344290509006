import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { CachedImage } from '../../../models';
import { ApiService } from '../../api/api.module';
import { MatSnackBar } from '@angular/material';

@Component({
	selector: 'app-cached-image',
	templateUrl: './cached-image.component.html',
	styleUrls: ['./cached-image.component.css']
})
export class CachedImageComponent implements OnInit {
	displayedColumns: string[] = ['Id', 'Image', 'ContentType', 'UpdatedAt', 'update'];
	imageDatasource: any = new MatTableDataSource<CachedImage>([]);
	@ViewChild(MatPaginator, {static:false}) paginator: MatPaginator;
	images: Array<CachedImage> = []
	pages = -1
	find = ""

	constructor(private api: ApiService, public snackBar: MatSnackBar) {
	}
	openSnackBar(message: string, action: string) {
		this.snackBar.open(message, action, {
			duration: 5000,
		});
	}
	onCreateFromFile(event) {
		if (event.target.files.length > 0) {
			this.toBase64(event.target.files[0], (data) => {
				var tmp = new CachedImage()
				tmp.data = data
				tmp.id = 0
				this.api.postCachedImage(tmp).subscribe((resp) => {
					if (resp.status == 200) {
						this.openSnackBar("L'image a été créée avec succès", "ok")
					} else {
						this.openSnackBar("Une erreur s'est produite lors de la creation de l'image", "ok")
						console.log(resp)
					}
				})
			})
		}
	}

	onUpdateFromFile(event, img) {
		if (event.target.files.length > 0) {
			this.toBase64(event.target.files[0], (data) => {
				img.data = data
				this.api.postCachedImage(img).subscribe((resp) => {
					if (resp.status == 200) {
						this.openSnackBar("L'image a ete mise a jours avec success", "ok")
					} else {
						this.openSnackBar("Une erreur c'est produits lors de la  mise a jours de l'image", "ok")
						console.log(resp)
					}
				})
			})
		}
	}

	toBase64(file: any, cb: any) {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb((reader.result as string).split(',')[1]);
		};
		reader.onerror = (error) => {
			this.openSnackBar("Erreur lors de la lecture du fichier", "ok")
			console.log('Error: ', error);
		};
	}

	ngOnInit() {
		this.load()
	}

	onPageChange(e: any) {
		if ((e.pageIndex + 1) * e.pageSize >= this.images.length) {
			this.load()
		}
	}

	load() {
		this.pages += 1
		this.api.getCachedImageList(this.find, this.pages).subscribe((data) => {
			for (var i in data.body) {
				this.images.push(data.body[i])
			}
			this.imageDatasource = new MatTableDataSource<CachedImage>(this.images);
			this.imageDatasource.paginator = this.paginator;
		})
	}

	onInputChange(e: any) {
		this.onFind(e.srcElement.value)
	}

	onFind(value: string) {
		this.pages = -1
		this.paginator.firstPage()
		this.images = []
		this.find = value
		this.load()
	}
}
