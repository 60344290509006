import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api/api.module';
import {ExtractParams} from '../../../models'

@Component({
	selector: 'app-extract-params',
	templateUrl: './extract-params.component.html',
	styleUrls: ['./extract-params.component.scss']
})
export class ExtractParamsComponent implements OnInit {

	constructor(private api:ApiService) { }
	pages = -1
	find = ""
	params:ExtractParams[] = []

	load() {
		this.pages += 1
		this.api
		.getExtractParams(this.find, this.pages).subscribe(data =>{
			for (var i in data.body) {
				this.params.push(data.body[i])
			}
		})
	}

	ngOnInit() {
		this.load()
	}


}
