export class TriggerConditionAnd {
	left: string
	operator: string
	constant: boolean
	right: string
	position: number

	public constructor(init?: Partial<TriggerConditionAnd>) {
		Object.assign(this, init);
		if (this.right != undefined && this.right != null && this.right.startsWith("{{")) {
			this.constant = false
			return
		}
		this.constant = true
	}

	setLeft(value: TriggerPossibility) {
		this.left = value.value
		if (value.sign.length > 0) {
			this.operator = value.sign[0]
		} else {
			this.operator = ""
		}
		this.constant = false
		if (value.possibility.length > 0) {
			this.right = value.possibility[0].value
		} else {
			this.right = ""
		}
	}
}

export class TriggerConditionOr {
	description: string
	position: number
	conditions: Array<TriggerConditionAnd>
	public constructor(init?: Partial<TriggerConditionOr>) {
		Object.assign(this, init);
		this.conditions = []
		for (var i in init.conditions) {
			this.conditions.push(new TriggerConditionAnd(init.conditions[i]))
		}
	}

	public addConditionAnd() {
		this.conditions.push(new TriggerConditionAnd({ position: (this.conditions || []).length }))
	}


	private reloadPosition() {
		for (var i in this.conditions) {
			this.conditions[i].position = Number(`${i}`)
		}
	}
	public removeConditionAnd(index: number) {
		this.conditions.splice(index, 1);
		this.reloadPosition()
	}
}

export const TriggerProtocols = [
	{
		name: "Email Basic",
		value: "basic_email",
		params: {
			emails: ""
		}
	},
	{
		name: "Sms Basic",
		value: "basic_sms",
		params: {
			number: ""
		}
	},
	{
		name: "Partage entre compte",
		value: "share_account",
		params: {
			emails: ""
		}
	}
]

export class TriggerOutput {
	position: number
	protocol: string
	params: any
	public constructor(init?: Partial<TriggerOutput>) {
		Object.assign(this, init);

	}

	public setProto(value) {
		for (var i in TriggerProtocols) {
			if (TriggerProtocols[i].value == value) {
				this.params = {}
				Object.assign(this.params, TriggerProtocols[i].params);
				this.protocol = value
				return
			}
		}
	}
}


export class Trigger {
	id: number
	name: string
	description: string
	user_id: number
	resource_id: number
	resource_type: string
	conditions: Array<TriggerConditionOr>
	outputs: Array<TriggerOutput>

	public constructor(init?: Partial<Trigger>) {
		Object.assign(this, init);
		this.conditions = []
		for (var i in init.conditions) {
			this.conditions.push(new TriggerConditionOr(init.conditions[i]))
		}
		this.outputs = []
		for (var i in init.outputs) {
			this.outputs.push(new TriggerOutput(init.outputs[i]))
		}
	}

	public addConditionOr() {
		var tmp = new TriggerConditionOr({ conditions: [] })
		tmp.addConditionAnd()
		this.conditions.push(tmp)
	}

	public removeConditionOr(index: number) {
		this.conditions.splice(index, 1);
	}

	public addOutput() {
		this.outputs.push(new TriggerOutput({ position: (this.outputs || []).length }))
	}



}

export class TriggerPossibilityValue {
	title: string
	value: string

	public constructor(init?: Partial<TriggerPossibilityValue>) {
		Object.assign(this, init);
	}
}

export class TriggerPossibility {
	title: string
	value: string
	sign: Array<string>
	possibility: Array<TriggerPossibilityValue>

	public constructor(init?: Partial<TriggerPossibility>) {
		Object.assign(this, init);
	}
}