import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api/api.module';

@Component({
	selector: 'app-stat-new-checkin',
	templateUrl: './stat-new-checkin.component.html',
	styleUrls: ['./stat-new-checkin.component.scss']
})
export class StatNewCheckinComponent implements OnInit {

	constructor(private api: ApiService) { }
  // lineChart
  public barChartOptions:any = {
  	scaleShowVerticalLines: false,
  	responsive: true
  };
  public barChartLabels:string[] = [];
  public barChartType:string = 'line';
  public barChartLegend:boolean = true;
  
  public barChartData:any[] = [
  {data: [], label: 'Last Week'},
  {data: [], label: 'This Week'}
  ];

  public barChartColor:Array<any> = [
  {
    backgroundColor: 'rgba(128, 102, 224, .1)',
    pointBackgroundColor: 'rgba(128, 102, 224, .1)',
    borderColor: 'rgba(128, 102, 224, .5)',
  },
  {
    backgroundColor: 'rgba(148,159,177,0.2)',
    borderColor: 'rgba(148,159,177,.1)',
  }
  ]

  public chartClicked(e:any):void {
  	console.log(e);
  }
  
  public chartHovered(e:any):void {
  	console.log(e);
  }

  Last7Days () {

  	var result = [];
  	for (var i=0; i<7; i++) {
  		var d = new Date();
  		d.setDate(d.getDate() - i);
  		result.push( `${d.getDate()}/${d.getMonth()+1}` )
  	}
  	this.barChartLabels = result
  	return(result.join(','));
  }

  ngOnInit() {
  	this.Last7Days()
  	this.api.getCheckinStateWeek().subscribe(data =>{
  		var tmp = [
  		{data: [], label: 'This Week'},
  		{data: [], label: 'Last Week'},
  		]
  		for (var i in data.body.Period) {
  			tmp[0].data.push(data.body.Period[i].Count)
  			tmp[1].data.push(data.body.LastPeriod[i].Count)
  		}
  		this.barChartData = tmp
  	})
  }

}
