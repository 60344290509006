import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-translation-selection-list-modal',
	templateUrl: './translation-selection-list-modal.component.html',
	styleUrls: ['./translation-selection-list-modal.component.css']
})

export class TranslationSelectionListModalComponent implements OnInit {

	constructor(
		public dialogRef: MatDialogRef<TranslationSelectionListModalComponent>, 
		@Inject(MAT_DIALOG_DATA) public params: any
	) { }

	ngOnInit() {
	}
}
