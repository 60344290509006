import { Component, OnInit, Input,SimpleChange, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../api/api.module';

@Component({
	selector: 'app-picture-cache',
	templateUrl: './picture-cache.component.html',
	styleUrls: ['./picture-cache.component.css']
})
export class PictureCacheComponent implements OnInit {
	@Input() path  :string;
	@ViewChild('image', {static:false}) img: ElementRef;
	haveImage:boolean = false;
	// default = "
	constructor(private api: ApiService) { }

	ngOnInit() {
		// this.source = this.default
	}

	reload(){
		console.log(this.path)
		if (this.path && this.path.length > 0){
			this.api.getCachedPicture(this.path, true).subscribe(data => {
				this.haveImage = true 
				setTimeout(()=>{
					if (this.img != null && this.img != undefined) {
						this.img.nativeElement.src =  "data:image/jpeg;base64," + data.body.data
					}
				}, 100)
			})
		} else {
			this.haveImage = false
		}
	}

	ngOnChanges(changes: {[propKey: string]: SimpleChange}) { 
		this.reload()
	}

}
