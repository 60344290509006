import { Component, OnInit, Input, Inject } from '@angular/core';
import { ApiService } from '../../api/api.module';
import { Process } from '../../../models';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationEditSelectionListComponent } from '../../component/translation-edit-selection/translation-edit-selection.component';
import { Category, Translation } from '../../../models';

@Component({
	selector: 'app-process-editor',
	templateUrl: './process-editor.component.html',
	styleUrls: ['./process-editor.component.css']
})
export class ProcessEditorComponent implements OnInit {
	_process: Process = null;

	constructor(
		private api: ApiService, 
		public snackBar: MatSnackBar,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: Category,
		) { }

	@Input('process')
	set process(value: Process) {
		console.log(value)
		this._process = value
	}

	get process() {
		return this._process
	}

	translations: Translation[] = []
	page = -1
	haveNext = true

	ngOnInit() {
		
	}

	onImageUpdate(cached) {
		this._process.cached_id = cached.id
	}

	save() {
		this.api.postProcess(this.process).subscribe(res => {
			this._process.id = res.body.id
			this.snackBar.open("Sauvegarde reussi", "OK", {
				duration: 5000,
				verticalPosition: 'top',
			});
		}, err => {
			console.log(err)
			this.snackBar.open("Erreur lor de la Sauvegarde", "OK", {
				duration: 5000,
				verticalPosition: 'top',
			});
		})

		console.log(this._process)
	}

	openEdit(): void {
		this.page += 1

		this.api.getTranslations("", this.page, false).subscribe(rep => {
			rep.body.forEach(element => {
				this.translations.push(element)
			});
			this.haveNext = rep.body.length == 50 ? true : false
		})
		
		const dialogRef = this.dialog.open(TranslationEditSelectionListComponent, {
			maxWidth: '90vw',
			maxHeight: '90vh',
			height: '90%',
			width: '90%',
			data: this.translations
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
			console.log(result)
			if (result != undefined) {
				this.data.title = result.id
			}
		});
	}
}
