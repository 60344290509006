import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AffiliationCodeClass } from '../../../../../models';
@Component({
	selector: 'app-affiliation-code-params',
	templateUrl: './affiliation-code-params.component.html',
	styleUrls: ['./affiliation-code-params.component.css']
})
export class AffiliationCodeParamsComponent implements OnInit {

	constructor() { }
	@Input('affiliationCode') affiliationCode: AffiliationCodeClass = new AffiliationCodeClass()
	ngOnInit() {

	}

}
