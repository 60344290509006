
export class CachedImage {
	id: number
	content_type: string
	data: string
	createdAt: string
	updatedAt: object

	public constructor(init?: Partial<CachedImage>) {
		Object.assign(this, init);
	}

}