import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AffiliationCodeClass, AffiliationCodeSuperAdmin, AffiliationCodeParameters } from '../../../../../models';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-affiliation-code-super-admin',
	templateUrl: './affiliation-code-super-admin.component.html',
	styleUrls: ['./affiliation-code-super-admin.component.css']
})
export class AffiliationCodeSuperAdminComponent implements OnInit {
	displayedColumns: string[] = ['UserId', 'TemplateId', 'RemoveOnCleanup'];
	datasource: MatTableDataSource<AffiliationCodeSuperAdmin>
	alsotrue = true
	_params: AffiliationCodeParameters

	@Input('params') set params(value) {
		console.log("set")
		this._params = value
		this.datasource = new MatTableDataSource(value.auto_super_admin)
	}
	constructor(private cdRef: ChangeDetectorRef) { }

	ngOnInit() {
	}

	addRow() {
		this._params.auto_super_admin.push(new AffiliationCodeSuperAdmin({
			user_id: 0,
			remove_on_cleanup: true,
		}))
		this.datasource.data = this._params.auto_super_admin
	}
}
