import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'wp-btn',
	templateUrl: './wp-btn.component.html',
	styleUrls: ['./wp-btn.component.scss']
})
export class WpBtnComponent implements OnInit {
	@Input() type:string;

	constructor() { }

	ngOnInit() {
	}

}
