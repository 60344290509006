import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../api/api.module';
import { Translation } from '../../../models';
@Component({
	selector: 'app-translation-list',
	templateUrl: './translation-list.component.html',
	styleUrls: ['./translation-list.component.css']
})
export class TranslationListComponent implements OnInit {
	@Input("short") short: boolean = false;
	translations: Translation[] = []
	page = -1
	search = ""
	loading = false
	haveNext = true
	exactMatch = false

	constructor(private api: ApiService) { }
	ngOnInit() {
		this.loadNext()
	}

	loadNext() {
		this.page += 1
		this.loading = true

		this.api.getTranslations(this.search, this.page, this.exactMatch).subscribe(rep => {
			this.loading = false
			rep.body.forEach(element => {
				this.translations.push(element)
			});
			this.haveNext = rep.body.length == 50 ? true : false
		}, () => {
			this.loading = false
		})
	}

	onInputChange() {
		this.page = -1
		this.translations = []
		this.loadNext()
	}
	
	add() {
		this.translations.unshift(new Translation())
	}
}
