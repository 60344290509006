import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router, RouteReuseStrategy } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app/app.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { LeftBarComponent } from './left-bar/left-bar.component';
import { HomeComponent } from './pages/home/home.component';
import { UserComponent } from './pages/user/user.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ApiService, AuthInterceptor } from './api/api.module';
import { AdminPermission, AdminPermissionComponent, AdminGuard } from './api/admin-permission.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserOneComponent} from './pages/user-one/user-one.component';
import { ImgCacheComponent } from './component/img-cache/img-cache.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './guard/auth.guard';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ChartsModule } from 'ng2-charts';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { StatNewUserComponent } from './pages/home/stat-new-user/stat-new-user.component';
import { StatNewCheckinComponent } from './pages/home/stat-new-checkin/stat-new-checkin.component';
import { StatNewCheckoutComponent } from './pages/home/stat-new-checkout/stat-new-checkout.component';
import { StatNewReportComponent } from './pages/home/stat-new-report/stat-new-report.component';
import { FieldsComponent, FieldEditDialog } from './fields/fields.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TriggerComponent } from './pages/trigger/trigger.component';
import { TriggerOneComponent } from './pages/trigger-one/trigger-one.component';
import { TriggerConditionComponent } from './pages/trigger-one/trigger-condition/trigger-condition.component';
import { TriggerOutputComponent } from './pages/trigger-one/trigger-output/trigger-output.component';
import { WpInputComponent } from './component/wp-input/wp-input.component';
import { RightModalComponent } from './component/right-modal/right-modal.component';
import { ExtractParamsComponent } from './pages/extract-params/extract-params.component';
import { ExtractParamsOneComponent } from './pages/extract-params-one/extract-params-one.component';
import { MiniReportComponent } from './component/mini-report//mini-report.component';
import { PictureCacheComponent } from './component/picture-cache/picture-cache.component';
import { ReportsListComponent } from './component/reports-list/reports-list.component';
import { BottomSheetExport } from './component/reports-list/bottom-sheet-export';
import { WpBtnComponent } from './component/wp-btn/wp-btn.component';
import {
	MatAutocompleteModule,
	MatBadgeModule,
	MatBottomSheetModule,
	MatButtonModule,
	MatButtonToggleModule,
	MatCardModule,
	MatCheckboxModule,
	MatChipsModule,
	MatDatepickerModule,
	MatDialogModule,
	MatDividerModule,
	MatExpansionModule,
	MatGridListModule,
	MatIconModule,
	MatInputModule,
	MatListModule,
	MatMenuModule,
	MatNativeDateModule,
	MatPaginatorModule,
	MatProgressBarModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatRippleModule,
	MatSelectModule,
	MatSidenavModule,
	MatSliderModule,
	MatSlideToggleModule,
	MatSnackBarModule,
	MatSortModule,
	MatStepperModule,
	MatTableModule,
	MatTabsModule,
	MatToolbarModule,
	MatTooltipModule,
	MatTreeModule,
} from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PlanComponent } from './component/plan/plan.component';
import { AddCreditFormComponent } from './component/add-credit-form/add-credit-form.component';
import { ExtractCsvComponent } from './pages/extract-csv/extract-csv.component';
import { CachedImageComponent } from './pages/cached-image/cached-image.component';
import { ProcessComponent } from './pages/process/process.component';
import { CategorySelectorComponent } from './component/category-selector/category-selector.component';
import { ProcessCategorySelectorComponent } from './pages/process/category-selector/process-category-selector.component';
import { ProcessSelectorComponent, ProcessEditorModal } from './component/process-selector/process-selector.component';
import { ProcessEditorComponent } from './component/process-editor/process-editor.component';
import { TriggerConditionOrComponent } from './pages/trigger-one/trigger-condition-or/trigger-condition-or.component';
import { UserInfosComponent, UserInfosAddPackComponent, UserInfosRemovePackComponent } from './pages/user-one/user-infos/user-infos.component';
import { UserPlanComponent } from './pages/user-one/user-plan/user-plan.component';
import { NotPermitedComponent } from './pages/not-permited/not-permited.component';
import { AffiliationCodeComponent } from './pages/affiliation-code/affiliation-code.component';
import { AffiliationCodeOneComponent } from './pages/affiliation-code/affiliation-code-one/affiliation-code-one.component';
import { AffiliationCodeEditComponent } from './pages/affiliation-code/affiliation-code-edit/affiliation-code-edit.component';
import { AffiliationCodePacksComponent } from './pages/affiliation-code/affiliation-code-edit/affiliation-code-packs/affiliation-code-packs.component';
import { AffiliationCodeSuperAdminComponent } from './pages/affiliation-code/affiliation-code-edit/affiliation-code-super-admin/affiliation-code-super-admin.component';
import { AffiliationCodeSuperTeamComponent } from './pages/affiliation-code/affiliation-code-edit/affiliation-code-super-team/affiliation-code-super-team.component';
import { AffiliationCodeSubscriptionComponent } from './pages/affiliation-code/affiliation-code-edit/affiliation-code-subscription/affiliation-code-subscription.component';
import { AffiliationCodeParamsComponent } from './pages/affiliation-code/affiliation-code-edit/affiliation-code-params/affiliation-code-params.component';
import { AffiliationCodeUserComponent, DialogAffiliationCodeUserAdd } from './pages/affiliation-code/affiliation-code-one/affiliation-code-user/affiliation-code-user.component';
import { AffiliationCodeAutoWebhooksComponent } from './pages/affiliation-code/affiliation-code-edit/affiliation-code-auto-webhooks/affiliation-code-auto-webhooks.component';
import { CategoryListComponent, SearchCatPipe, CategoryEditDialog } from './component/category-list/category-list.component';
import { NameOrTitlePipe } from './component/pipe/nameOrTitle.pipe';
import { CategoryComponent } from './pages/category/category.component';
import { CategorySelectorModalComponent } from './component/category-selector-modal/category-selector-modal.component';
import { CategoryMultiListComponent } from './component/category-multi-list/category-multi-list.component';
import { TranslationCardComponent } from './component/translation-card/translation-card.component';
import { TranslationListComponent } from './component/translation-list/translation-list.component';
import { TranslationComponent } from './pages/translation/translation.component';
import { TranslationSelectionListComponent } from './component/translation-selection-list/translation-selection-list.component';
import { TranslationSelectionListModalComponent } from './component/translation-selection-list-modal/translation-selection-list-modal.component';
import { TranslationEditSelectionListComponent } from './component/translation-edit-selection/translation-edit-selection.component';
import { CacheRouteReuseStrategy } from './strategy/cache-route-reuse.strategy';

const appRoutes: Routes = [
	{ path: '', component: HomeComponent, canActivate: [AuthGuard] },
	{ path: 'user', component: UserComponent, canActivate: [AuthGuard, AdminGuard]},
	{ path: 'error-permited', component: NotPermitedComponent, canActivate: [AuthGuard] },
	{ path: 'user/:id', component: UserOneComponent, canActivate: [AuthGuard, AdminGuard] },
	{ path: 'report', component: ReportsComponent, canActivate: [AuthGuard, AdminGuard] },
	{ path: 'login', component: LoginComponent },
	{ path: 'field', component: FieldsComponent, canActivate: [AuthGuard, AdminGuard] },
	{ path: 'field', component: FieldEditDialog, canActivate: [AuthGuard, AdminGuard] },
	{ path: 'trigger', component: TriggerComponent, canActivate: [AuthGuard, AdminGuard] },
	{ path: 'trigger/:id', component: TriggerOneComponent, canActivate: [AuthGuard, AdminGuard] },
	{ path: 'extract_params', component: ExtractParamsComponent, canActivate: [AuthGuard, AdminGuard] },
	{ path: 'extract-csv', component: ExtractCsvComponent, canActivate: [AuthGuard, AdminGuard] },
	{ path: 'extract_params/:id', component: ExtractParamsOneComponent, canActivate: [AuthGuard, AdminGuard] },
	{ path: 'cached-image', component: CachedImageComponent, canActivate: [AuthGuard, AdminGuard] },
	{ path: 'process', component: ProcessComponent, canActivate: [AuthGuard, AdminGuard] },
	{ path: 'affiliation', component: AffiliationCodeComponent, canActivate: [AuthGuard, AdminGuard] },
	{ path: 'translation', component: TranslationComponent, canActivate: [AuthGuard, AdminGuard] },
	{ path: 'affiliation/:id', component: AffiliationCodeOneComponent, canActivate: [AuthGuard, AdminGuard] },
	{ path: 'affiliation/edit/:id', component: AffiliationCodeEditComponent, canActivate: [AuthGuard, AdminGuard] },
	{ path: 'category', component: CategoryComponent, canActivate: [AuthGuard, AdminGuard] },

	// { path: '**', component: PageNotFoundComponent }
];


@NgModule({
	declarations: [
		AppComponent,
		TopBarComponent,
		LeftBarComponent,
		HomeComponent,
		UserComponent,
		ReportsComponent,
		UserOneComponent,
		ImgCacheComponent,
		LoginComponent,
		StatNewUserComponent,
		StatNewCheckinComponent,
		StatNewCheckoutComponent,
		StatNewReportComponent,
		FieldsComponent,
		FieldEditDialog,
		TriggerComponent,
		TriggerOneComponent,
		TriggerConditionComponent,
		TriggerOutputComponent,
		WpInputComponent,
		RightModalComponent,
		ExtractParamsComponent,
		ExtractParamsOneComponent,
		MiniReportComponent,
		PictureCacheComponent,
		ReportsListComponent,
		WpBtnComponent,
		PlanComponent,
		AddCreditFormComponent,
		ExtractCsvComponent,
		CachedImageComponent,
		ProcessComponent,
		CategorySelectorComponent,
		ProcessCategorySelectorComponent,
		ProcessSelectorComponent,
		BottomSheetExport,
		AdminPermissionComponent,
		ProcessEditorComponent,

		TriggerConditionOrComponent,

		UserInfosComponent,

		UserPlanComponent,
		UserInfosAddPackComponent,
		UserInfosRemovePackComponent,
		NotPermitedComponent,
		AffiliationCodeComponent,
		AffiliationCodeOneComponent,
		AffiliationCodeEditComponent,
		AffiliationCodePacksComponent,
		AffiliationCodeSuperAdminComponent,
		AffiliationCodeSuperTeamComponent,
		AffiliationCodeSubscriptionComponent,
		AffiliationCodeParamsComponent,
		AffiliationCodeUserComponent,
		DialogAffiliationCodeUserAdd,
		AffiliationCodeAutoWebhooksComponent,
		CategoryListComponent,
		CategoryComponent,
		CategoryEditDialog,
		SearchCatPipe,
		NameOrTitlePipe,
		CategorySelectorModalComponent,
		ProcessEditorModal,
		CategoryMultiListComponent,
		TranslationCardComponent,
		TranslationListComponent,
		TranslationComponent,
		TranslationSelectionListComponent,
		TranslationSelectionListModalComponent,
		TranslationEditSelectionListComponent
	],
	imports: [

		DragDropModule,
		ReactiveFormsModule,
		FormsModule,
		BrowserModule,
		BrowserAnimationsModule,
		ChartsModule,
		InfiniteScrollModule,
		HttpClientModule,
		MatAutocompleteModule,
		MatBadgeModule,
		MatBottomSheetModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatDividerModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSortModule,
		MatStepperModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatTreeModule,
		RouterModule.forRoot(
			appRoutes,
			{ enableTracing: false, useHash: true } // <-- debugging purposes only
		)
	],
	providers: [ApiService, HttpClientModule, AdminGuard, AuthGuard, AdminPermission, 
		{provide: HTTP_INTERCEPTORS, useFactory: function(router: Router) {return new AuthInterceptor(router);},multi: true, deps: [Router]},
		{provide: RouteReuseStrategy, useClass: CacheRouteReuseStrategy }],
		bootstrap: [AppComponent],
		exports: [MatDatepickerModule,
		BottomSheetExport,
	],
	entryComponents: [BottomSheetExport, UserInfosAddPackComponent, UserInfosRemovePackComponent, DialogAffiliationCodeUserAdd, CategoryEditDialog, CategorySelectorModalComponent, TranslationSelectionListModalComponent, ProcessEditorModal, TranslationEditSelectionListComponent]
})
export class AppModule {
	constructor() {

	}
}
// platformBrowserDynamic().bootstrapModule(AppModule);	