import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AffiliationCodeClass, AffiliationCodePack, AutoPackParams } from '../../../../../models';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-affiliation-code-packs',
	templateUrl: './affiliation-code-packs.component.html',
	styleUrls: ['./affiliation-code-packs.component.css']
})
export class AffiliationCodePacksComponent implements OnInit {
	displayedColumns: string[] = ['PackId', 'RemoveOnCleanup'];
	datasource: MatTableDataSource<AffiliationCodePack>

	_params: AutoPackParams

	@Input('params') set params(value) {
		console.log("set")
		this._params = new AutoPackParams()
		if (value == null || value == undefined) {
			this.datasource = new MatTableDataSource([])
			return
		}
		this._params = value.auto_packs
		this.datasource = new MatTableDataSource(this._params.packs)
	}

	constructor(private cdRef: ChangeDetectorRef) {
		this._params = new AutoPackParams()
	}

	ngOnInit() { }

	addRow() {
		this._params.packs.push(new AffiliationCodePack({
			pack_id: 0,
			remove_on_cleanup: true,
		}))
		this.datasource.data = this._params.packs
	}
}
