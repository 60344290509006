import { Component, OnInit,Input, EventEmitter, Output } from '@angular/core';
import { debounce } from 'underscore';

@Component({
	selector: 'app-wp-input',
	templateUrl: './wp-input.component.html',
	styleUrls: ['./wp-input.component.scss']
})
export class WpInputComponent implements OnInit {

	@Input() placeholder:string;
	@Output() changeValue = new EventEmitter();
	constructor() { }

	onChangeEvent = null;
	ngOnInit() {

		this.onChangeEvent = debounce((data)=>{
			console.log("data", data)
			if (typeof data == "string"){
				this.changeValue.emit(data)
			}
		}, 300)
	}

}
