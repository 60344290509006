import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-user-plan',
	templateUrl: './user-plan.component.html',
	styleUrls: ['./user-plan.component.css']
})
export class UserPlanComponent implements OnInit {
	@Input('right') right;

	constructor() { }
	ngOnInit() {
	}

}
