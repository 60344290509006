import { Component, OnInit, Input, Output, EventEmitter, Inject} from '@angular/core';
import { Category } from '../../../models';
import { ApiService } from '../../api/api.module';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationEditSelectionListComponent } from '../../component/translation-edit-selection/translation-edit-selection.component';
import { Translation } from '../../../models';


@Component({
	selector: 'app-category-list',
	templateUrl: './category-list.component.html',
	styleUrls: ['./category-list.component.css']
})

export class CategoryListComponent implements OnInit {
	list: Array<Category>
	addEnable: boolean = false
	_parent: string = ""
	loading: boolean = false
	@Input('dragDisabled') dragDisabled: boolean = true
	@Input('parent')
	set parent(value: string) {
		this._parent = value
		this.loadCategory(value)
	}

	@Input('parentId') parentId: number = null
	@Output('onSelect') _select = new EventEmitter();
	@Input('canEdit') canEdit: boolean = false

	search = ""

	selected: Category = null


	constructor(
		private api: ApiService, 
		public dialog: MatDialog) { }

	ngOnInit() {
	}

    trackById(index: number, category: Category) {
        return category.id
    }

	onSelect(c: Category) {
		this.selected = c
		this._select.emit(c)
	}

	onEdit(c) {
		this.openEdit(c)
	}


	onCreate() {
		var tmp = new Category({
			id: 0,
			type: this._parent,
			position: this.list.length,
			parent_id: this.parentId,
		})
		this.openEdit(tmp)
	}

	loadCategory(parent) {
		this.list = new Array<Category>()
		this.addEnable = true
		this._parent = parent

		if (parent === "") {
			return
		}
		
		if (parent === "item") {
			this.loading = true
		}
		
		this.api.getAllCategory(parent).subscribe(rep => {
			this.loading = false
			for (var i in rep.body) {
				this.list.push(new Category(rep.body[i]))
			}
		})
	}

	drop(event: CdkDragDrop<Category[]>) {
		if (!this.canEdit) {
			return
		}
		if (this.search.length > 0) {
			return
		}
		moveItemInArray(this.list, event.previousIndex, event.currentIndex);
		var toSend = []
		for (let i in this.list) {
			this.list[i].position = 0 + Number(i)
			toSend.push({ id: this.list[i].id, position: this.list[i].position })
		}
		this.api.postCategoryOrder(toSend).subscribe(rep => {
			console.log(rep)
		})
	}

	openEdit(cat: Category): void {
		const dialogRef = this.dialog.open(CategoryEditDialog, {
			data: cat
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
			console.log(result)
			if (result != undefined) {
				this.api.postCategory(result).subscribe(res => {
					this.loadCategory(cat.type)
				})
			} 
		});
	}
}



import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'searchCat' })
export class SearchCatPipe implements PipeTransform {
	transform(category: Category[], seach: string) {
		if (seach.length == 0) {
			return category
		}
		return category.filter(cat => {
			return (cat.value.search(seach) != -1) || (cat._title == null) || (cat._title.fr.search(seach) != -1) || (cat._title.en.search(seach) != -1)
		});
	}
}


@Component({
	selector: 'category-edit-dialog',
	templateUrl: 'category-edit-dialog.html',
})
export class CategoryEditDialog {

	constructor(
		public dialogRef: MatDialogRef<CategoryEditDialog>,
		@Inject(MAT_DIALOG_DATA) 
		public data: Category,
		public dialog: MatDialog,
		private api: ApiService
	) { }

	newCategory : Category = new Category(this.data)

	translations: Translation[] = []
	page = -1
	haveNext = true

	ngOnInit() {
		this.page += 1
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	add(): void {
		this.dialogRef.close(this.newCategory)
	}

	openEdit(): void {
		this.api.getTranslations("", this.page, false).subscribe(rep => {
			rep.body.forEach(element => {
				this.translations.push(element)
			});
			this.haveNext = rep.body.length == 50 ? true : false
		})

		const dialogRef = this.dialog.open(TranslationEditSelectionListComponent, {
			maxWidth: '90vw',
			maxHeight: '90vh',
			height: '90%',
			width: '90%',
			data: this.translations
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log('The dialog was closed');
			console.log(result)
			if (result != undefined) {
				this.newCategory.title = result.id
			}
		});
	}
}

