import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslationSelectionListModalComponent } from '../../component/translation-selection-list-modal/translation-selection-list-modal.component';
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	constructor(public dialog: MatDialog) { }


	ngOnInit() {
	}

}
