import { Injectable, Component, Input } from '@angular/core';
import { User, Right } from '../../models';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { environment } from '../../environments/environment';


@Injectable()
export class AdminPermission {
	static getCurrentUser(): User {
		var user = localStorage.getItem('currentUser') as any
		user = JSON.parse(user)
		return new User(user)
	}

	static isOK(permited: Array<string>): boolean {
		var u = AdminPermission.getCurrentUser()
		for (var i in permited) {
			if (permited[i] == u.email) {
				return true
			}
		}
		return false
	}

	static haveRight(action: string): boolean {
		if (environment.authorization[action] != undefined && environment.authorization[action] != null) {
			return AdminPermission.isOK(environment.authorization[action])
		} else {
			return true
		}
	}
}

@Injectable()
export class AdminGuard implements CanActivate {
	constructor(private router: Router) { }
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Promise<boolean> | boolean {
		console.log(next.routeConfig.path)
		if (AdminPermission.haveRight(next.routeConfig.path)) {
			return true
		} else {
			this.router.navigate(['error-permited'], { queryParams: { action: next.routeConfig.path } });
			return false;
		}
	}
}

@Component({
	selector: 'ifRight',
	template: '<ng-content *ngIf="haveRight"></ng-content>'
	// templateUrl: './left-bar.component.html',
	// styleUrls: ['./left-bar.component.scss']
})
export class AdminPermissionComponent {
	haveRight: boolean = false;
	constructor() { }
	@Input('perm') set perm(value) {
		this.haveRight = AdminPermission.haveRight(value)
	}
}