import { Component, OnInit } from '@angular/core';
import { AffiliationCodeClass } from '../../../../models';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ApiService } from '../../../api/api.module';
import { MatSnackBar } from '@angular/material';
@Component({
	selector: 'app-affiliation-code-edit',
	templateUrl: './affiliation-code-edit.component.html',
	styleUrls: ['./affiliation-code-edit.component.css']
})
export class AffiliationCodeEditComponent implements OnInit {
	affiliationCode: AffiliationCodeClass = new AffiliationCodeClass()


	constructor(private api: ApiService, private route: ActivatedRoute, private router: Router, public snackBar: MatSnackBar) {
		this.router.routeReuseStrategy.shouldReuseRoute = function () {
			return false;
		};
	}

	reload() {
		if (this.route.snapshot.paramMap.get('id') == "new") {
			return
		}
		var id = Number(this.route.snapshot.paramMap.get('id'))
		this.api.getOneAffiliationCode(id).subscribe(data => {
			console.log(data.body)
			this.affiliationCode = new AffiliationCodeClass(data.body)
		})
	}

	ngOnInit() {
		this.reload()
	}
	send() {
		this.api.postAffiliationCode(this.affiliationCode).subscribe(data => {
			if (data.status == 200) {
				this.snackBar.open("Le code d'affiliation a ete sauvgardé avec success", "ok", { duration: 5000 })
				this.affiliationCode = new AffiliationCodeClass(data.body)
			} else {

			}
		}, err => {
			this.snackBar.open(`Une Erreur ces produite lors de la sauvgarde ${err.status} error: ${JSON.stringify(err.error)}`, "ok", { duration: 5000 })
			console.log(err)
		})
		console.log(this.affiliationCode)
	}
}
