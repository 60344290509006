import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { TriggerOutput, TriggerProtocols,Template} from '../../../../models'

@Component({
	selector: 'app-trigger-output',
	templateUrl: './trigger-output.component.html',
	styleUrls: ['./trigger-output.component.scss']
})
export class TriggerOutputComponent implements OnInit {
	allProtocols = TriggerProtocols

	@Input() output  :TriggerOutput;
	@Output() outputChange = new EventEmitter();
	@Input() possibility:any;

	@Output('remove') removeEmit = new EventEmitter();

	constructor(private cd:ChangeDetectorRef) { }

	ngOnInit() {}
	onProtoChange(value){
		this.output.setProto(value)
	}
	remove() {
		this.removeEmit.emit()
		this.cd.detectChanges()
	}

}
