import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api/api.module';
import { Report } from '../../../models';

@Component({
	selector: 'app-reports',
	templateUrl: './reports.component.html',
	styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

	constructor(private api: ApiService) {
	}

	ngOnInit() {
	}



}
