import { Translation } from '../common/translation'


export class AffiliationCodePack {
	pack_id: number
	remove_on_cleanup: boolean

	public constructor(init?: Partial<AffiliationCodePack>) {
		Object.assign(this, init);
	}
}

export class AutoPackParams {
	cleanup: boolean
	packs: Array<AffiliationCodePack>
	public constructor(init?: Partial<AutoPackParams>) {
		Object.assign(this, init);
		this.packs = []
		if (init != null && init != undefined) {
			for (var i in init.packs) {
				this.packs.push(new AffiliationCodePack(init.packs[i]))
			}
		}
	}
}

export class AutoWebhooks {
	url: string
	secret_key: string
	local: string
	template_id: number
	owner: number
	update: boolean
	init: boolean
	first_finished: boolean
	second_finished: boolean
	version: number
	public constructor(init?: Partial<AutoWebhooks>) {
		Object.assign(this, init);
		this.version = 2
	}
}

export class AffiliationCodeTeam {
	user_id: number
	remove_on_cleanup: boolean

	public constructor(init?: Partial<AffiliationCodeTeam>) {
		Object.assign(this, init);
	}
}

export class AffiliationCodeSubscription {
	plan_code: string
	customer_id: string
	remove_on_cleanup: boolean
	change_current_if_needed: boolean

	public constructor(init?: Partial<AffiliationCodeSubscription>) {
		Object.assign(this, init);
	}
}

export class AffiliationCodeSuperAdmin {
	user_id: number
	template_id: number
	remove_on_cleanup: boolean
	public constructor(init?: Partial<AffiliationCodeSuperAdmin>) {
		Object.assign(this, init);
	}
}

export class AffiliationCodeParameters {
	auto_packs: AutoPackParams
	auto_team: AffiliationCodeTeam
	auto_subscription: AffiliationCodeSubscription
	auto_super_admin: Array<AffiliationCodeSuperAdmin>
	auto_webhooks: Array<AutoWebhooks>

	public constructor(init?: Partial<AffiliationCodeParameters>) {
		Object.assign(this, init);
		this.auto_packs = new AutoPackParams()
		this.auto_super_admin = []
		this.auto_webhooks = []
		this.auto_team = new AffiliationCodeTeam()
		this.auto_subscription = new AffiliationCodeSubscription()
		if (init == undefined || init == null) {
			return
		}
		for (var i in init.auto_super_admin) {
			this.auto_super_admin.push(new AffiliationCodeSuperAdmin(init.auto_super_admin[i]))
		}
		for (var i in init.auto_webhooks) {
			this.auto_webhooks.push(new AutoWebhooks(init.auto_webhooks[i]))
		}
		this.auto_packs = new AutoPackParams(init.auto_packs)
		this.auto_team = new AffiliationCodeTeam(init.auto_team)
		this.auto_subscription = new AffiliationCodeSubscription(init.auto_subscription)
	}
}


export class AffiliationCodeClass {
	id: number
	code: string
	url: string
	owner: number
	logo_id: number
	title: number
	_title: Translation
	description: number
	_description: Translation
	activation_description: number
	_activation_description: Translation
	remove_description: number
	_remove_description: Translation
	secret: string
	email: string
	phone: string
	createdAt: string
	updatedAt: string
	parameters: AffiliationCodeParameters

	public constructor(init?: Partial<AffiliationCodeClass>) {
		Object.assign(this, init);
		this.parameters = new AffiliationCodeParameters()
		if (init != undefined) {
			this._activation_description = new Translation(init._activation_description)
			this._remove_description = new Translation(init._remove_description)
			this._title = new Translation(init._title)
			this._description = new Translation(init._description)
			if (init.parameters != null && init.parameters != undefined) {
				this.parameters = new AffiliationCodeParameters(init.parameters)
			} else {
				this.parameters = new AffiliationCodeParameters()

			}
		}
	}
}