import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../../api/api.module';
import { Translation } from '../../../models';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
	selector: 'app-translation-edit-selection',
	templateUrl: './translation-edit-selection.html',
	styleUrls: ['../translation-selection-list/translation-selection-list.component.css', '../translation-card/translation-card.component.scss']
})
export class TranslationEditSelectionListComponent implements OnInit {
    constructor(
		private api: ApiService,
		public dialogRef: MatDialogRef<TranslationEditSelectionListComponent>,
		public dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public translations: Translation[],
	) { }

	// translations: Translation[] = []
	tr: Translation
	translationsSelected: Translation[] = []
	page = -1
	search = ""
	haveNext = false
	loading = false
	sign = "+";

	ngOnInit() {
		// this.loadNext()
    }
	
	trackById(index: number, tr: Translation) {
        return tr.id
	}
	
    loadNext() {
		this.page += 1
		this.loading = true
		
		this.api.getTranslations(this.search, this.page, false).subscribe(rep => {
			rep.body.forEach(element => {
				this.translations.push(element)
			});
			this.loading = false
			this.haveNext = rep.body.length == 50 ? true : false
		}, () => {
			this.loading = false
		})
	}

	onInputChange() {
		this.page = -1
		this.translations = []
		this.loadNext()
	}

	onChange(tr :Translation) {
		console.log(tr)
		this.dialogRef.close(tr)
	}

	add() {
		this.translations.unshift(new Translation())
	}

	postTranslation(tr :Translation) {
		console.log(tr)
		this.api.postTranslation(tr).subscribe(rep => {
			Object.assign(tr, rep.body);
			console.log(rep)
		})
	}
}