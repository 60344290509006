
export class Subscription {
	id:number
	platform:string
	is_trial:boolean
	reference:string
	name:string
	user_id:number
	start:string
	end:string
	options:Array<RightCredit> = []
	cache_control:string | null


	public constructor(init?:Partial<Subscription>) {
		Object.assign(this, init);
		if (init != undefined || init != null) {
			for(var i in this.options) {
				this.options[i] = new RightCredit(init.options[i])
			}
		}
	}

}
export class RightCredit {
	id:number
	user_id:number
	credit:number | null
	used:number
	code:string
	source:string
	boolean:boolean
	subsciption_id:number | null
	disable:boolean

	public constructor(init?:Partial<RightCredit>) {
		Object.assign(this, init);
	}

	public is(key:string):boolean{
		return this.code === key
	}
}

export class Right {
	id:number
	first_name:string
	last_name:string
	email:string
	subscription_id:number
	active:boolean
	need_sync:boolean
	access_test:boolean
	credits:Array<RightCredit>
	subscription:Subscription

	public constructor(init?:Partial<Right>) {
		Object.assign(this, init);
		this.credits = []
		for(var i in init.credits) {
			this.credits.push(new RightCredit(init.credits[i]))
		}
		this.subscription = new Subscription(init.subscription)
	}

	haveRightTo(key:string): boolean{
		for(var i in this.credits){
			if (this.credits[i].is(key)){
				return true
			}
		}
		return false
	}
} 