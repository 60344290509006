import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { AffiliationCodeClass } from '../../../models';
import { ApiService } from '../../api/api.module';

@Component({
	selector: 'app-affiliation-code',
	templateUrl: './affiliation-code.component.html',
	styleUrls: ['./affiliation-code.component.scss']
})
export class AffiliationCodeComponent implements OnInit {
	displayedColumns: string[] = ['Code', 'Description', 'Logo', 'Option'];
	@ViewChild(MatPaginator, {static:false}) paginator: MatPaginator;
	datasource: any = new MatTableDataSource<AffiliationCodeClass>();

	onLoading: Boolean = false
	offset: number = -10
	data: Array<AffiliationCodeClass> = []
	find: string = ""

	onPageChange(e: any) {
		if ((e.pageIndex + 1) * e.pageSize >= this.data.length) {
			this.load()
		}
	}

	onFind(e: any) {
		var value = e.srcElement.value
		this.offset = -10
		this.data = []
		this.find = value
		this.paginator.firstPage()
		this.load()
	}

	constructor(private api: ApiService) { }
	load() {
		if (this.onLoading == false) {
			this.onLoading = true
		} else {
			return
		}
		this.offset += 10
		this.api.getAffiliationCodes(this.find, this.offset).subscribe((data) => {
			for (var i in data.body) {
				this.data.push(new AffiliationCodeClass(data.body[i]))
			}
			this.onLoading = false
			this.datasource = new MatTableDataSource<AffiliationCodeClass>(this.data);
			this.datasource.paginator = this.paginator;
		})
	}
	ngOnInit() {
		this.load()
	}

}
