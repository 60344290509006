import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Process } from '../../../models';

@Component({
	selector: 'app-process',
	templateUrl: './process.component.html',
	styleUrls: ['./process.component.css']
})
export class ProcessComponent implements OnInit {
	parent: string = ""
	parentId: number = null
	isSubcategory: boolean = false
	selected: Process = null;
	constructor(private router: Router, private route: ActivatedRoute, ) { }

	ngOnInit() {

	}

	onSelect(process) {
		this.selected = process
		// console.log(process)
	}
}
