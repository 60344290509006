import { Component, OnInit, Input } from '@angular/core';
import { Translation } from '../../../models';
import { ApiService } from '../../api/api.module';

@Component({
	selector: 'app-translation-card',
	templateUrl: './translation-card.component.html',
	styleUrls: ['./translation-card.component.scss']
})
export class TranslationCardComponent implements OnInit {
	@Input("translation") tr: Translation
	@Input("addEnabled") addEnabled: boolean = false
	edit: string = ""
	@Input("short") set short(value) {
		if (value == true || value == "true") {
			this.trs = [{ name: "Francais", iso: "fr" }]
		} else {
			this.trs = this.AllTrs
		}
	}
	trs = []
	AllTrs = [
		{
			name: "Francais",
			iso: "fr"
		},
		{
			name: "Anglais",
			iso: "en"
		},
		{
			name: "Espagnol",
			iso: "es"
		},
		{
			name: "Italien",
			iso: "it"
		},
		{
			name: "Portugais",
			iso: "pt"
		},
		{
			name: "Néerlandais",
			iso: "nl"
		},
		{
			name: "Allemand",
			iso: "de"
		},
		{
			name: "Polonais",
			iso: "pl"
		},
		{
			name: "Roumain",
			iso: "ro"
		},
		{
			name: "Suédois",
			iso: "sv"
		},
		{
			name: "Turc",
			iso: "tr"
		},
		{
			name: "Tchèque",
			iso: "cs"
		},
		{
			name: "Slovène",
			iso: "sl"
		},
	]
	show = 3;
	sign = "+";

	constructor(private api: ApiService) { }

	ngOnInit() {
	}

	onInputChange() {
		console.log(this.tr)
		this.api.postTranslation(this.tr).subscribe(rep => {
			Object.assign(this.tr, rep.body);
			this.edit = ""
			console.log(rep)
		})
	}

	buttonClicked() {
		if (this.sign == "+") {
			this.show = this.AllTrs.length
			this.sign = "-"
		} else {
			this.show = 3
			this.sign = "+"
		}
	}
}
