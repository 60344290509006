import { Component, OnInit, Inject } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
	selector: 'category-selector-modal',
	templateUrl: './category-selector-modal.component.html',
	styleUrls: ['./category-selector-modal.component.css']
})
export class CategorySelectorModalComponent implements OnInit {

	constructor(public dialogRef: MatDialogRef<CategorySelectorModalComponent>, @Inject(MAT_DIALOG_DATA) public params: any) { }

	ngOnInit() {
	}

}


