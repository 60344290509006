import { Translation } from "../common/translation"
export class Category {
	id: number
	createdAt: string
	updatedAt: string

	title: number
	cached_id: number

	type: string
	value: string

	parent_id: number
	position: number

	_title: Translation

	public get titleTr() {
		if (this._title != null && this._title != undefined) {

			return this._title.fr
		}
		return ""
	}
	public constructor(init?: Partial<Category>) {
		Object.assign(this, init);
	}
} 
