
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'NameOrTitle' })
export class NameOrTitlePipe implements PipeTransform {
	transform(category: any[], seach: string) {
		if (seach == undefined || seach.length == 0) {
			return category
		}
		return category.filter(cat => {
			return (cat.name.search(seach) != -1) || (cat._title == null) || (cat._title.fr.search(seach) != -1) || (cat._title.en.search(seach) != -1)
		});
	}
}
