import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../api/api.module';
import { CachedImage } from '../../../models';
import { MatSnackBar } from '@angular/material';

@Component({
	selector: 'img-cache',
	template: `
	<mat-progress-bar mode="indeterminate" *ngIf="loading" class="progress"></mat-progress-bar>
	<input hidden type="file" #imgFileInput (change)="onNewImage($event)" />
	<div class="_img-cache" *ngIf="!loading"  (click)="updateable ? imgFileInput.click() : return" [ngStyle]="{'background-image': 'url(' + source + ')'}" ></div>`,
	styleUrls: ['./img-cache.component.scss']
})
export class ImgCacheComponent implements OnInit {
	_id: number;
	loading: boolean;
	@Input('updateable') updateable: boolean = false;
	@Output('onUpdate') _onUpdate = new EventEmitter<CachedImage>();

	@Input('id')
	set id(value: number) {
		this._id = value
		this.reload()
	}
	@Input() default: string;

	source: string = ""
	constructor(
		private api: ApiService, 
		public snackBar: MatSnackBar
	) { }

	ngOnInit() {
		this.source = this.default
	}

	openSnackBar(message: string, action: string) {
		this.snackBar.open(message, action, {
			duration: 5000,
		});
	}

	onNewImage(event) {
		var create = false
		var tmp = new CachedImage()
		if (this._id > 0) {
			tmp.id = this._id
			create = false
		} else {
			tmp.id = 0
			create = true
		}
		this.onUpdateFromFile(event, tmp, create)
	}

	onUpdateFromFile(event, img, create) {
		if (event.target.files.length > 0) {
			this.toBase64(event.target.files[0], (data) => {
				img.data = data
				this.api.postCachedImage(img).subscribe((resp) => {
					if (resp.status == 200) {
						if (create) {
							this.openSnackBar("L'image a ete creer avec success", "ok")
						} else {
							this.openSnackBar("L'image a ete mise a jours avec success", "ok")
						}
						if (this._onUpdate != null && this._onUpdate != undefined) {
							this._onUpdate.emit(new CachedImage(resp.body))
							this.id = resp.body.id
						}
					} else {
						if (create) {
							this.openSnackBar("Une erreur c'est produits lors de la  creation de l'image", "ok")
						} else {
							this.openSnackBar("Une erreur c'est produits lors de la  mise a jours de l'image", "ok")
						}
						console.log(resp)
					}
				})
			})
		}
	}

	toBase64(file: any, cb: any) {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb((reader.result as string).split(',')[1]);
		};
		reader.onerror = (error) => {
			this.openSnackBar("Erreur lors de la lecture du fichier", "ok")
			console.log('Error: ', error);
		};
	}

	onClick() {

	}

	reload() {
		this.loading = true
		if (this._id > 0) {
			this.api.getCachedImage(this._id).subscribe(data => {
				this.source = "data:image/jpeg;base64," + data.body.data
				this.loading = false
			}, err => {
				this.loading = false
				this.source = this.default
				console.log(err)
			})
		} else {
			this.loading = false
			this.source = this.default
		}
	}


}
