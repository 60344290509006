import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { Template, TriggerConditionAnd } from '../../../../models'

@Component({
	selector: 'app-trigger-condition',
	templateUrl: './trigger-condition.component.html',
	styleUrls: ['./trigger-condition.component.scss']
})
export class TriggerConditionComponent implements OnInit {


	constructor() { }
	@Input() condition: TriggerConditionAnd;
	@Output() conditionChange = new EventEmitter();

	_possibility: any = [];

	@Input('possibility')
	set possibility(value: string) {
		this._possibility = value
		for (var i in this._possibility) {
			if (this._possibility[i].value == this.condition.left) {
				this.currentLeft = this._possibility[i]
				return
			}
		}
	}
	get possibility() {
		return this._possibility || []
	}

	@Output('remove') removeEmit = new EventEmitter();
	currentLeft: any = null
	currentRight: any = null


	ngOnInit() { }

	onChangeConstant() {
		this.condition.right = ""
	}

	onChangeLeft(value) {
		for (var i in this._possibility) {
			if (this._possibility[i].value == value) {
				this.currentLeft = this._possibility[i]
				this.condition.setLeft(this.currentLeft)
				return
			}
		}
	}
	onChangeRight(value) {
		for (var i in this._possibility) {
			if (this._possibility[i].value == value) {
				this.currentRight = this._possibility[i]
				return
			}
		}
	}

	remove() {
		this.removeEmit.emit()
	}

}
