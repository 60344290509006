import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User, Right, Template, Trigger, ExtractParams, Report, CachedImage, Category, Process, AffiliationCodeClass, Translation, Subscription } from '../../models';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiService {
	httpOptions: any

	constructor(private http: HttpClient) {
		this.refreshOption()
	}
	refreshOption() { 
		var user = localStorage.getItem('currentUser') as any
		if (user == null) {
			return
		}
		user = JSON.parse(user)
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Authorization': user.access_token,
				// 'Accept-Encoding': 'gzip, deflate'
			}),
			observe: 'response'
		};
	}
	getAdminUrl(path: string) {
		return environment.apiAdmin + path
	}
	getCacheUrl(path: string) {
		return environment.apiCache + path
	}
	getUrl(path: string) {
		return environment.api + path
	}
	getAdminAuthUrl(path: string) {
		return environment.apiAdminAuth + path
	}

	getRight(find: string, pages: number): Observable<HttpResponse<User[]>> {
		return this.http.get<User[]>(this.getUrl(`/right`), this.httpOptions) as Observable<HttpResponse<User[]>>;
	}

	getUsers(find: string, pages: number): Observable<HttpResponse<User[]>> {
		return this.http.get<User[]>(this.getAdminUrl(`/user?pages=` + pages + "&find=" + find), this.httpOptions) as Observable<HttpResponse<User[]>>;
	}

	getOneUsers(id: number): Observable<HttpResponse<User>> {
		return this.http.get<User>(this.getAdminUrl(`/user/` + id), this.httpOptions) as Observable<HttpResponse<User>>;
	}
	getOneUsersRight(id: number): Observable<HttpResponse<Right>> {
		return this.http.get<Right>(this.getAdminUrl(`/user/` + id + `/right`), this.httpOptions) as Observable<HttpResponse<Right>>;
	}

	getUsersSubscription(ids: string): Observable<HttpResponse<Subscription[]>> {
		return this.http.get<Subscription[]>(this.getAdminUrl(`/user/subscription?ids=`+ids), this.httpOptions) as Observable<HttpResponse<Subscription[]>>;
	}

	getCachedImage(id: number): Observable<HttpResponse<any>> {
		return this.http.get<any>(this.getCacheUrl(`/cache/` + id), this.httpOptions) as Observable<HttpResponse<any>>;
	}

	getUserStateWeek(): Observable<HttpResponse<any>> {
		return this.http.get<any>(this.getAdminUrl(`/stats/user/week`), this.httpOptions) as Observable<HttpResponse<any>>;
	}
	getReportStateWeek(): Observable<HttpResponse<any>> {
		return this.http.get<any>(this.getAdminUrl(`/stats/report/week`), this.httpOptions) as Observable<HttpResponse<any>>;
	}
	getCheckinStateWeek(): Observable<HttpResponse<any>> {
		return this.http.get<any>(this.getAdminUrl(`/stats/checkin/week`), this.httpOptions) as Observable<HttpResponse<any>>;
	}
	getCheckoutStateWeek(): Observable<HttpResponse<any>> {
		return this.http.get<any>(this.getAdminUrl(`/stats/checkout/week`), this.httpOptions) as Observable<HttpResponse<any>>;
	}
	getAllCategory(master: string): Observable<HttpResponse<Category>> {
		return this.http.get<any>(this.getAdminUrl(`/categorys/` + master), this.httpOptions) as Observable<HttpResponse<Category>>;
	}

	postCategory(category: Category): Observable<HttpResponse<Category>> {
		return this.http.post<Category>(this.getAdminUrl(`/categorys`), category, this.httpOptions) as Observable<HttpResponse<Category>>;
	}
	postCategoryOrder(categorysPosition: any[]): Observable<HttpResponse<Category>> {
		return this.http.post<Category>(this.getAdminUrl(`/categorys/order`), categorysPosition, this.httpOptions) as Observable<HttpResponse<Category>>;
	}

	getAllProcessByCategory(category: string): Observable<HttpResponse<Process>> {
		return this.http.get<any>(this.getAdminUrl(`/process?category=` + category), this.httpOptions) as Observable<HttpResponse<Process>>;
	}

	postProcess(process: Process): Observable<HttpResponse<Process>> {
		return this.http.post<Process>(this.getAdminUrl(`/process`), process, this.httpOptions) as Observable<HttpResponse<Process>>;
	}
	getAllField(categoryid: number, find: string, pages: number): Observable<HttpResponse<any>> {
		return this.http.get<any>(this.getAdminUrl(`/fields?category=` + categoryid + `&find=` + find + `&pages=` + pages), this.httpOptions) as Observable<HttpResponse<any>>;
	}
	getTemplate(templateId: number): Observable<HttpResponse<Template>> {
		return this.http.get<any>(this.getAdminUrl(`/template/${templateId}`), this.httpOptions) as Observable<HttpResponse<Template>>;
	}
	getTriggers(find: string, pages: number): Observable<HttpResponse<Trigger[]>> {
		return this.http.get<Trigger[]>(this.getAdminUrl(`/trigger?pages=` + pages + "&find=" + find), this.httpOptions) as Observable<HttpResponse<Trigger[]>>;
	}
	getTrigger(id: number): Observable<HttpResponse<Trigger>> {
		return this.http.get<Trigger>(this.getAdminUrl(`/trigger/${id}`), this.httpOptions) as Observable<HttpResponse<Trigger>>;
	}
	postTrigger(data: Trigger): Observable<HttpResponse<Trigger>> {
		return this.http.post<Trigger>(this.getAdminUrl(`/trigger`), data, this.httpOptions) as Observable<HttpResponse<Trigger>>;
	}
	getExtractParams(find: string, pages: number): Observable<HttpResponse<ExtractParams[]>> {
		return this.http.get<ExtractParams[]>(this.getAdminUrl(`/extract_params?pages=` + pages + "&find=" + find), this.httpOptions) as Observable<HttpResponse<ExtractParams[]>>;
	}
	getExtractParamsOne(id: number): Observable<HttpResponse<ExtractParams>> {
		return this.http.get<ExtractParams>(this.getAdminUrl(`/extract_params/${id}`), this.httpOptions) as Observable<HttpResponse<ExtractParams>>;
	}
	postExtractParams(data: ExtractParams): Observable<HttpResponse<ExtractParams>> {
		return this.http.post<ExtractParams>(this.getAdminUrl(`/extract_params`), data, this.httpOptions) as Observable<HttpResponse<ExtractParams>>;
	}

	getReports(find: string, pc:string, userId: number, pages: number): Observable<HttpResponse<Report>> {
		return this.http.get<Report>(this.getAdminUrl(`/report?find=${find}&user=${userId}&pages=${pages}&proovcode=${pc}`), this.httpOptions) as Observable<HttpResponse<Report>>;
	}

	getReport(proovCode: string): Observable<HttpResponse<Report>> {
		return this.http.get<Report>(this.getAdminUrl(`/report/${proovCode}`), this.httpOptions) as Observable<HttpResponse<Report>>;
	}


	getReportPdfUrl(proov_code: string, state: string): Observable<HttpResponse<any>> {
		return this.http.get<any>(this.getAdminUrl(`/report/${proov_code}/pdf?state=${state}`), this.httpOptions) as Observable<HttpResponse<any>>;
	}

	getCachedImageList(find: string, pages: number): Observable<HttpResponse<any>> {
		return this.http.get<any>(this.getAdminUrl(`/cached-image?pages=${pages}&find=${find}`), this.httpOptions) as Observable<HttpResponse<any>>;
	}

	postCachedImage(data: CachedImage): Observable<HttpResponse<any>> {
		return this.http.post<CachedImage>(this.getAdminUrl(`/cached-image`), data, this.httpOptions) as Observable<HttpResponse<CachedImage>>;
	}

	postUser(user: User): Observable<HttpResponse<User>> {
		return this.http.post<User>(this.getAdminUrl(`/user`), user, this.httpOptions) as Observable<HttpResponse<User>>;
	}

	getReportPdf(proov_code: string, state: string): any {
		return new Promise((success, reject) => {
			this.getReportPdfUrl(proov_code, state).subscribe((res) => {
				let url = res.body.url
				this.http.get(url, { responseType: 'blob' }).subscribe((data) => {
					console.log(data)
					success(data)
				})
			})
		})
	}

	getCachedPicture(path: string, small: boolean) {
		var proov_code = path.split('/')[1]
		var name = path.split('/')[2]
		if (small) {
			var splited = name.split('.')
			name = `${splited[0]}/thumb.${splited[1]}`
		}
		return this.http.get<any>(this.getCacheUrl(`/picture?name=${name}&pc=${proov_code}`), this.httpOptions) as Observable<HttpResponse<any>>;
	}

	getAffiliationCodes(find: string, offset: number): Observable<HttpResponse<AffiliationCodeClass>> {
		return this.http.get<AffiliationCodeClass>(this.getAdminUrl(`/affiliation?find=${find}&offset=${offset}`), this.httpOptions) as Observable<HttpResponse<AffiliationCodeClass>>;
	}

	getOneAffiliationCode(id: number): Observable<HttpResponse<AffiliationCodeClass>> {
		return this.http.get<AffiliationCodeClass>(this.getAdminUrl(`/affiliation/${id}`), this.httpOptions) as Observable<HttpResponse<AffiliationCodeClass>>;
	}

	postAffiliationCode(ac: AffiliationCodeClass): Observable<HttpResponse<AffiliationCodeClass>> {
		return this.http.post<AffiliationCodeClass>(this.getAdminUrl(`/affiliation`), ac, this.httpOptions) as Observable<HttpResponse<AffiliationCodeClass>>;
	}
	getAffiliationCodeUsers(acId: number, find: string, offset: number): Observable<HttpResponse<AffiliationCodeClass>> {
		return this.http.get<AffiliationCodeClass>(this.getAdminUrl(`/affiliation/${acId}/user?find=${find}&offset=${offset}`), this.httpOptions) as Observable<HttpResponse<AffiliationCodeClass>>;
	}
	activeAffiliationCode(acId: number, uid: number): Observable<HttpResponse<AffiliationCodeClass>> {
		return this.http.put<AffiliationCodeClass>(this.getAdminUrl(`/affiliation/${acId}/user?user_id=${uid}`), {}, this.httpOptions) as Observable<HttpResponse<AffiliationCodeClass>>;
	}

	removeAffiliationCode(acId: number, uid: number): Observable<HttpResponse<AffiliationCodeClass>> {
		return this.http.delete<AffiliationCodeClass>(this.getAdminUrl(`/affiliation/${acId}/user?user_id=${uid}`), this.httpOptions) as Observable<HttpResponse<AffiliationCodeClass>>;
	}
	getTranslations(find: string, pages: number, match: boolean): Observable<HttpResponse<Translation[]>> {
		return this.http.get<Translation[]>(this.getAdminUrl(`/translation?find=${find}&pages=${pages}&match=${match}`), this.httpOptions) as Observable<HttpResponse<Translation[]>>;
	}
	getOneTranslation(id: number): Observable<HttpResponse<Translation>> {
		return this.http.get<Translation>(this.getAdminUrl(`/translation/${id}`), this.httpOptions) as Observable<HttpResponse<Translation>>;
	}
	postTranslation(tr: Translation): Observable<HttpResponse<Translation>> {
		return this.http.post<Translation>(this.getAdminUrl(`/translation`), tr, this.httpOptions) as Observable<HttpResponse<Translation>>;
	}
	getUserToken(id: number): Observable<HttpResponse<any>> {
		return this.http.get<any>(this.getAdminUrl(`/user/${id}/token`), this.httpOptions) as Observable<HttpResponse<any>>;
	}
	postUserToken(id: number): Observable<HttpResponse<any>> {
		return this.http.post<any>(this.getAdminUrl(`/user/${id}/token`), {}, this.httpOptions) as Observable<HttpResponse<any>>;
	}
	postFields(field: {}): Observable<HttpResponse<any>> {
		return this.http.post<any>(this.getAdminUrl(`/fields`), field, this.httpOptions ) as Observable<HttpResponse<any>>;
	}
	postAuth(msg: {}): Observable<HttpResponse<string>> {
		return this.http.post<any>(this.getAdminUrl(`/auth`), msg, this.httpOptions ) as Observable<HttpResponse<string>>;
	}
	resetPassword(id: number): Observable<HttpResponse<any>> {
		return this.http.post<any>(this.getAdminUrl(`/user/${id}/password`), {}, this.httpOptions ) as Observable<HttpResponse<User[]>>;
	}
	disconnectUser(id: number): Observable<HttpResponse<any>> {
		return this.http.post<any>(this.getAdminAuthUrl(`/users/${id}/disconnect`), {}, this.httpOptions ) as Observable<HttpResponse<any>>;
	}
	
	extractReport(params: {}):Observable<HttpResponse<any>> {
		return this.http.post<any>(this.getAdminUrl(`/extract_report`), params, this.httpOptions ) as Observable<HttpResponse<any>>;
	}
}

export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(x => this.handleError(x))); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
	}
	
    private handleError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401 || err.status === 403) {
			localStorage.removeItem("currentUser")
            this.router.navigateByUrl(`/login`);
            return throwError(err.message);
        }
        return throwError(err)
    }
}
