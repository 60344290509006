import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AffiliationCodeClass, AffiliationCodeSubscription, AffiliationCodeParameters } from '../../../../../models';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-affiliation-code-subscription',
	templateUrl: './affiliation-code-subscription.component.html',
	styleUrls: ['./affiliation-code-subscription.component.css']
})
export class AffiliationCodeSubscriptionComponent implements OnInit {
	displayedColumns: string[] = ['PlanCode', 'CustomerId', 'RemoveOnCleanup', 'ChangeCurrentIfNeeded'];
	datasource: MatTableDataSource<AffiliationCodeSubscription>

	_params: AffiliationCodeParameters

	@Input('params') set params(value) {
		this._params = value
		this.datasource = new MatTableDataSource([value.auto_subscription])
	}
	constructor(private cdRef: ChangeDetectorRef) { }

	ngOnInit() {
	}
}