import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-affiliation-code-one',
	templateUrl: './affiliation-code-one.component.html',
	styleUrls: ['./affiliation-code-one.component.scss']
})
export class AffiliationCodeOneComponent implements OnInit {

	constructor() { }
	id: number = 0
	ngOnInit() {
	}

}
