import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Category } from '../../../models';

@Component({
	selector: 'app-category-multi-list',
	templateUrl: './category-multi-list.component.html',
	styleUrls: ['./category-multi-list.component.css']
})
export class CategoryMultiListComponent implements OnInit {
	@Input('racine') categoryParent: string = ""

	@Input('canEdit') canEdit: boolean = false
	@Input('showExtraColumn') showExtraColumn: boolean = false
	@Input('showSubCategory') showSubCategory: boolean = true

	@Output('onCategorySelected') onCategorySelected = new EventEmitter();
	@Output('onSubCategorySelected') onSubCategorySelected = new EventEmitter();

	subCategoryType: string = ""
	subCategoryParentId: number = null

	constructor() {

	}

	ngOnInit() {
	}


	_onCategorySelected(category: Category) {
		this.subCategoryType = category.value
		this.subCategoryParentId = category.id
		this.onCategorySelected.emit(category)
	}

	_onSubCategorySelected(category: Category) {
		console.log("ssub =", category)
		this.onSubCategorySelected.emit(category)
	}

}
