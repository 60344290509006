import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../api/api.module';
import { Template, Trigger } from '../../../models'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
	selector: 'app-trigger-one',
	templateUrl: './trigger-one.component.html',
	styleUrls: ['./trigger-one.component.scss']
})
export class TriggerOneComponent implements OnInit {

	constructor(private api: ApiService, private cd: ChangeDetectorRef, private route: ActivatedRoute, private router: Router) { }

	template: Template = null
	possibilityTemplate: any = null
	trigger: Trigger = new Trigger(
		{
			id: 0,
			name: "",
			description: "",
			resource_id: 0,
			resource_type: "template",
			user_id: 0,
			conditions: [],
			outputs: []
		}
	)
	reloadTemplate() {
		this.api.getTemplate(this.trigger.resource_id).subscribe(data => {
			this.template = new Template(data.body)
			this.possibilityTemplate = this.template.getAllPossibilityTrigger()
		})
	}

	addConditionOr() {
		this.trigger.addConditionOr()
	}
	addOutput() {
		this.trigger.addOutput()
	}

	loadTrigger(id: number) {
		this.api.getTrigger(id).subscribe(data => {
			this.trigger = new Trigger(data.body)
			console.log(this.trigger)
			this.reloadTemplate()
		})
	}

	removeConditionOr(index: number) {
		this.trigger.removeConditionOr(index)
	}
	removeOutput(index: number) {
		// this.trigger.removeOutput(index)
	}

	ngOnInit() {
		var id = this.route.snapshot.paramMap.get('id')
		if (id == "new") {
			console.log("new params")
			return
		} else {
			this.loadTrigger(Number(id))
		}
	}

	save() {
		console.log(this.trigger)
		console.log(JSON.stringify(this.trigger))
		this.api.postTrigger(this.trigger).subscribe(res => {
			this.trigger = new Trigger(res.body)
		})
	}
}
