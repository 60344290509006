import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Template, TriggerConditionOr } from '../../../../models'

@Component({
	selector: 'app-trigger-condition-or',
	templateUrl: './trigger-condition-or.component.html',
	styleUrls: ['./trigger-condition-or.component.css']
})
export class TriggerConditionOrComponent implements OnInit {

	@Input('condition-or') conditionOr: TriggerConditionOr;
	@Output() conditionChange = new EventEmitter();

	@Input() possibility: any;

	@Output('remove') removeEmit = new EventEmitter();

	constructor() { }

	ngOnInit() {
	}

	removeConditionAnd(i: number) {
		this.conditionOr.removeConditionAnd(i)
	}

	addConditionAnd() {
		this.conditionOr.addConditionAnd()
	}

	removeOr() {
		this.removeEmit.emit()
	}
}
