import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'app-right-modal',
	templateUrl: './right-modal.component.html',
	styleUrls: ['./right-modal.component.scss']
})
export class RightModalComponent implements OnInit {

	constructor() { }

	@Input('open') isOpened:boolean;
	@Output('close') onClose = new EventEmitter();


	ngOnInit() {
	}
}
