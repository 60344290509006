export class bddmodels {
	id: number
	createdAt: string
	updatedAt: string

	public constructor(init?: Partial<bddmodels>) {
		Object.assign(this, init);
	}
}

export class Translation {
	id: number
	fr: string
	en: string
	es: string
	nl: string
	it: string
	public constructor(init?: Partial<Translation>) {
		Object.assign(this, init);
	}
}

