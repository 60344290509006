// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  //Test
  // production: false,
  // apiAdmin: "https://bvjfek5n80.execute-api.eu-west-1.amazonaws.com/test",
  // api: "https://f8cocd3d1h.execute-api.eu-west-1.amazonaws.com/test",
  // apiCache: "https://f8cocd3d1h.execute-api.eu-west-1.amazonaws.com/test",
  // apiAdminAuth: "https://lhahbncum7.execute-api.eu-west-1.amazonaws.com/test",

  //Preprod
  production: true,
  apiAdmin:
    "https://jc1jofu3p4.execute-api.eu-west-1.amazonaws.com/preproduction",
  api: " https://a41ez21z17.execute-api.eu-west-1.amazonaws.com/production",
  apiCache: "https://cache.weproov.com/v1",
  apiAdminAuth:
    "https://dl4j2rvb5l.execute-api.eu-west-1.amazonaws.com/preproduction",

  //Prod
  // production: true,
  // apiAdmin: "https://api.admin.weproov.com/v1",
  // api: " https://a41ez21z17.execute-api.eu-west-1.amazonaws.com/production",
  // apiCache: "https://cache.weproov.com/v1",
  // apiAdminAuth: "https://dl4j2rvb5l.execute-api.eu-west-1.amazonaws.com/production",

  authorization: {
    // voire les champs sur l'admin
    field: [
      "jessica@weproov.com",
      "alexandre@weproov.com",
      "alexandre.schmoll@weproov.com",
      "etienne@weproov.com",
      "aurelien@weproov.com",
      "maxime.gleizes@weproov.com",
      "margaux@weproov.com",
      "yacine@weproov.com",
      "valentin.heftman@weproov.com",
      "gwenaelle@weproov.com",
    ],
    // voire la liste d'utilisateur
    user: [
      "jessica@weproov.com",
      "alexandre@weproov.com",
      "alexandre.schmoll@weproov.com",
      "etienne@weproov.com",
      "maxime.gleizes@weproov.com",
      "aurelien@weproov.com",
      "margaux@weproov.com",
      "yacine@weproov.com",
      "valentin.heftman@weproov.com",
      "gwenaelle@weproov.com",
    ],
    // voire plus d'information sur un utilisateur
    "user/:id": [
      "jessica@weproov.com",
      "alexandre@weproov.com",
      "alexandre.schmoll@weproov.com",
      "etienne@weproov.com",
      "maxime.gleizes@weproov.com",
      "aurelien@weproov.com",
      "margaux@weproov.com",
      "yacine@weproov.com",
      "valentin.heftman@weproov.com",
      "gwenaelle@weproov.com",
    ],
    // editer un utilisateur
    "user/:id/edit": [
      "jessica@weproov.com",
      "alexandre@weproov.com",
      "alexandre.schmoll@weproov.com",
      "etienne@weproov.com",
      "aurelien@weproov.com",
      "maxime.gleizes@weproov.com",
      "margaux@weproov.com",
      "yacine@weproov.com",
      "valentin.heftman@weproov.com",
      "gwenaelle@weproov.com",
    ],
    // voir les rapport
    report: [
      "jessica@weproov.com",
      "alexandre@weproov.com",
      "alexandre.schmoll@weproov.com",
      "pierrette@weproov.com",
      "etienne@weproov.com",
      "maxime.gleizes@weproov.com",
      "aurelien@weproov.com",
      "margaux@weproov.com",
      "valentin.heftman@weproov.com",
      "yacine@weproov.com",
      "gwenaelle@weproov.com",
    ],
    //
    trigger: [
      "jessica@weproov.com",
      "alexandre@weproov.com",
      "alexandre.schmoll@weproov.com",
      "etienne@weproov.com",
      "aurelien@weproov.com",
      "maxime.gleizes@weproov.com",
      "margaux@weproov.com",
      "yacine@weproov.com",
      "valentin.heftman@weproov.com",
      "gwenaelle@weproov.com",
    ],
    extract_params: [
      "jessica@weproov.com",
      "alexandre@weproov.com",
      "alexandre.schmoll@weproov.com",
      "etienne@weproov.com",
      "aurelien@weproov.com",
      "maxime.gleizes@weproov.com",
      "margaux@weproov.com",
      "yacine@weproov.com",
      "valentin.heftman@weproov.com",
      "gwenaelle@weproov.com",
    ],
    "extract-csv": [
      "jessica@weproov.com",
      "alexandre@weproov.com",
      "alexandre.schmoll@weproov.com",
      "etienne@weproov.com",
      "aurelien@weproov.com",
      "maxime.gleizes@weproov.com",
      "margaux@weproov.com",
      "yacine@weproov.com",
      "valentin.heftman@weproov.com",
      "gwenaelle@weproov.com",
    ],
    process: [
      "jessica@weproov.com",
      "alexandre@weproov.com",
      "alexandre.schmoll@weproov.com",
      "etienne@weproov.com",
      "aurelien@weproov.com",
      "maxime.gleizes@weproov.com",
      "margaux@weproov.com",
      "yacine@weproov.com",
      "valentin.heftman@weproov.com",
      "gwenaelle@weproov.com",
    ],
  },
};

