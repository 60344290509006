import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api/api.module';
import { User, Right } from '../../../models';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-user-one',
	templateUrl: './user-one.component.html',
	styleUrls: ['./user-one.component.scss']
})
export class UserOneComponent implements OnInit {
	addCreditsModal = false

	user: User = null
	right: Right = null
	constructor(
		private api: ApiService, 
		private route: ActivatedRoute, 
		private router: Router
	) {
		this.router.routeReuseStrategy.shouldReuseRoute = function () {
			return false;
		};
	}

	reload() {
		var id = Number(this.route.snapshot.paramMap.get('id'))
		this.api.getOneUsers(id).subscribe(data => {
			console.log(data.body)
			this.user = data.body
		})

		this.api.getOneUsersRight(id).subscribe(data => {
			console.log(data.body)
			this.right = new Right(data.body)
		})
	}

	ngOnInit() {
		this.reload()
	}
}
