import { Component, OnInit } from '@angular/core';
import { Trigger } from '../../../models'
import { ApiService } from '../../api/api.module';
@Component({
	selector: 'app-trigger',
	templateUrl: './trigger.component.html',
	styleUrls: ['./trigger.component.scss']
})
export class TriggerComponent implements OnInit {


	triggers: Trigger[] = []
	pages = -1
	find = ""

	constructor(private api: ApiService) {
	}

	load() {
		this.pages += 1
		this.api.getTriggers(this.find, this.pages).subscribe(data => {
			for (var i in data.body) {
				this.triggers.push(data.body[i])
			}
		})
	}

	ngOnInit() {
		this.load()
	}
	OnFindTemplateId(id: number) {

	}
	onFindUser(id: number) {

	}
}
