import { Component, Inject } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';

@Component({
	selector: 'bottom-sheet-export-sheet',
	templateUrl: 'bottom-sheet-export.html',
})
export class BottomSheetExport {
	constructor(private bottomSheetRef: MatBottomSheetRef<BottomSheetExport>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

	copyClipboard(event: MouseEvent) {
		var data = this.data[0]
		var input = document.createElement('textarea')
		input.value = data
		document.body.appendChild(input)
		input.select()
		input.setSelectionRange(0, data.length);
		console.log(document.execCommand('copy'))
		document.body.removeChild(input)
		this.bottomSheetRef.dismiss()
		event.preventDefault();
	}

	toFile(event: MouseEvent): void {
		event.preventDefault();
		var data = this.data[0]
		var report = JSON.parse(data)
		var link = document.createElement('a')

		let blob = data
		link.href = window.URL.createObjectURL(new Blob(this.data, { type: 'application/json' }))
		link.download = `${report.proov_code}.json`

		document.body.appendChild(link)
		link.click()

		setTimeout(function () {
			document.body.removeChild(link)
			window.URL.revokeObjectURL(link.href)
			this.bottomSheetRef.dismiss()
		}, 100)

	}
}