import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../api/api.module';
import { AdminGuard} from '../api/admin-permission.module';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-left-bar',
	templateUrl: './left-bar.component.html',
	styleUrls: ['./left-bar.component.scss']
})
export class LeftBarComponent implements OnInit {
	@Output() onClicked = new EventEmitter<boolean>();
	returnUrl:string
	constructor(
		private api: ApiService,
		private route: ActivatedRoute,
		private router: Router) { 
			this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
		}

	ngOnInit() {
	}

	onClick() {
		this.onClicked.emit(false);
	}

	logOut() {
		localStorage.removeItem('currentUser');
		location.reload();
	}

}
