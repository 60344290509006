import { Component, OnInit, Input, Inject } from '@angular/core';
import { User } from '../../../../models';
import { ApiService } from '../../../api/api.module';
// import { MatDialogModule } from '@angular/material/dialog';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatSnackBar } from '@angular/material';

@Component({
	selector: 'app-user-infos',
	templateUrl: './user-infos.component.html',
	styleUrls: ['./user-infos.component.css']
})
export class UserInfosComponent implements OnInit {
	_user: User = null
	@Input('user') set user(user) {
		this._user = user;
		this.getToken()
	}
	get user(): User {
		return this._user
	};
	edit: User = null;
	userToken = null

	constructor(private api: ApiService, public dialog: MatDialog, public snackBar: MatSnackBar) { }

	ngOnInit() {
	}

	startEdit() {
		this.edit = new User(this.user)
	}
	
	save() {
		this.api.postUser(this.edit).subscribe((resp) => {
			if (resp.status == 200) {
				this.user = new User(resp.body)
				this.edit = null
			}
		})
	}

	getToken() {
		if (this.user != null) {
			this.api.getUserToken(this.user.id).subscribe((resp) => {
				if (Object.keys(resp.body).length == 2) {
					this.userToken = JSON.stringify(resp.body)
				}
			})
		}
	}

	openSnackBar(message: string, action: string) {
		this.snackBar.open(message, action, {
			duration: 5000,
		});
	}

	updateToken() {
		if (this.user != null) {
			if (this.clickMethod()){
			this.api.postUserToken(this.user.id).subscribe((resp) => {
				if (Object.keys(resp.body).length == 2) {
					this.userToken = JSON.stringify(resp.body)
					this.openSnackBar("La modification du token a bien été prise en compte", "ok")

				}
				else {
					this.openSnackBar("Une erreur s'est produite lors de la génération du token", "ok")
					console.log(resp)
				}
			})
		}}
	}

	clickMethod() {
		var select = confirm("Confirmez-vous le changement de token ?") ? true : false;
		return select
	}

	addPack() {
		let dialogRef = this.dialog.open(UserInfosAddPackComponent, {});
		dialogRef.afterClosed().subscribe((add) => {
			if (add && dialogRef.componentInstance.pack_id > 0) {
				this.edit.pack.push(dialogRef.componentInstance.pack_id)
			}
		})
	}

	removePack(id: number) {
		let dialogRef = this.dialog.open(UserInfosRemovePackComponent, {
			data: id,
		});

		dialogRef.afterClosed().subscribe((remove) => {
			var tmp = []
			if (remove === "true") {
				for (var i in this.edit.pack) {
					if (this.edit.pack[i] == id) {
						continue
					}
					tmp.push(this.edit.pack[i])
				}
				this.edit.pack = tmp
			}
		})
	}

	resetPassword(){
		if (this.user != null) {
			if(confirm("Etes-vous sûr de vouloir reset le password de "+this.user.first_name + " " + this.user.last_name+ "?")) {
				this.api.resetPassword(this.user.id).subscribe((resp) => {
					console.log(resp.status)
					if (resp.status == 200) {
						this.openSnackBar("Le mail a été envoyé avec succès", "ok")
					} else {
						this.openSnackBar("Une erreur est survenue lors de l'envoi de mail", "ok")					
					}
					console.log(resp)
				})
			}
		}
	}

	deconnect() {
		if (this.user != null) {
			if(confirm("Etes-vous sûr de vouloir deconnecter "+this.user.first_name + " " + this.user.last_name + "?")) {
				this.api.disconnectUser(this.user.auth_id).subscribe((resp) => {
					console.log(resp.status)
					if (resp.status == 200){
						this.openSnackBar("L'utilisateur a bien été deconnecté", "ok")
					} else {
						this.openSnackBar("Une erreur est survenue lors de la deconnexion", "ok")					
					}
					console.log(resp)
				})
			}
		}	
	}
}


@Component({
	selector: 'app-user-infos-add-pack',
	templateUrl: './user-infos-add-pack.component.html',
})

export class UserInfosAddPackComponent {
	pack_id: number = 0
	constructor() { }
}


@Component({
	selector: 'app-user-infos-remove-pack',
	templateUrl: './user-infos-remove-pack.component.html',
})
export class UserInfosRemovePackComponent {
	constructor(
		private dialogRef: MatDialogRef<UserInfosRemovePackComponent>,
		@Inject(MAT_DIALOG_DATA) public id: any,
	) { }

	clickMethod() {
		var select = confirm("Confirmez-vous la supression du pack "+this.id) ? "true" : "false";
		this.dialogRef.close(select)
	}
}
