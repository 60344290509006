import {Translation} from '../common/translation'
import {TriggerPossibility, TriggerPossibilityValue} from '../trigger/trigger'

export class TemplateParameters {
	list: Array<number>
	_list: Array<Translation>
}
export class Template  {

	title: number
	_title: Translation


	dropoff:boolean
	full_dropoff:boolean
	auto_restart:boolean
	restart_state:string
	image_report:number
	type:string
	mails:string
	category_id:number
	owner:number
	pdf_header_textColor:string
	pdf_header_background_color:string

	parts:Array<TemplatePart>
	process:Array<TemplateProcess>

	public constructor(init?:Partial<Template>) {
		Object.assign(this, init);
		for(let i in this.parts) {
			this.parts[i] = new TemplatePart(this.parts[i])
		}
		for(let i in this.process) {
			this.process[i] = new TemplateProcess(this.process[i])
		}
	}

	getAllPossibilityTrigger() : any {
		var ret = []


		for(var i in this.parts){
			var push = (value) => {
				value.title = `${this.parts[i]._title.fr} >> ${value.title}`
				ret.push(value)
			}
			for(var j in this.parts[i].infos){
				push(this.parts[i].infos[j].possibilityValue(i, "value"))
				if (this.dropoff == true && this.parts[i].infos[j].dropoff == true){
					push(this.parts[i].infos[j].possibilityValue(i, "dropoff_value"))
					push(this.parts[i].infos[j].possibilityValue(i, "last"))
				}
			}
			for(var j in this.parts[i].supports){
				push(this.parts[i].supports[j].possibilityValue(i, "value"))
				if (this.dropoff == true && this.parts[i].supports[j].dropoff == true){
					push(this.parts[i].supports[j].possibilityValue(i, "dropoff_value"))
					push(this.parts[i].supports[j].possibilityValue(i, "last"))
				}
			}
			for(var j in this.parts[i].checklists){
				push(this.parts[i].checklists[j].possibilityValue(i, "value"))
				if (this.dropoff == true && this.parts[i].checklists[j].dropoff == true){
					push(this.parts[i].checklists[j].possibilityValue(i, "dropoff_value"))
					push(this.parts[i].checklists[j].possibilityValue(i, "last"))
				}
			}
		}

		for(var j in this.process){
			ret.push(this.process[j].possibilityValue("value"))
			if (this.dropoff == true ){
				push(this.process[j].possibilityValue( "dropoff_value"))
				push(this.process[j].possibilityValue( "last"))
			}
		}
		return ret
	}
}



export class TemplateProcess {
	title: number
	_title: Translation

	name: string
	category_id: string
	orientation: string
	position: number
	dropoff: boolean
	require: boolean
	process_id: number
	template_id: number
	no_restart: boolean

	public constructor(init?:Partial<TemplateProcess>) {
		Object.assign(this, init);
	} 


	possibilityValue(state) : TriggerPossibility {
		return new TriggerPossibility({
			title: `photoscan >> ${this._title.fr} ${state}`,
			value: `{{section[-1].process[${this.name}].${state}}}`,
			sign: ["==", "!="],
			possibility:[]
		})
	}
}

export class TemplatePart {

	title: number
	_title: Translation

	signer: boolean
	position: number
	category_id: number
	template_id: number
	item: boolean
	auto_restart: boolean

	infos:Array<TemplateInfos>
	supports:Array<TemplateSupports>
	checklists:Array<TemplateChecklists>

	public constructor(init?:Partial<TemplatePart>) {
		Object.assign(this, init);
		for(var i in this.infos){
			this.infos[i] = new TemplateInfos(this.infos[i])
		}
		for(var i in this.supports){
			this.supports[i] = new TemplateSupports(this.supports[i])
		}
		for(var i in this.checklists){
			this.checklists[i] = new TemplateChecklists(this.checklists[i])
		}
	}
}

export class TemplateInfos {
	title: number
	_title: Translation

	name: string
	dropoff: boolean
	field_id:number
	required: boolean
	section:string
	type:string
	position:number
	part_id:number

	parameters:TemplateParameters
	identifier:boolean
	forced:boolean
	referenced:boolean
	no_restart:boolean
	category_id:number

	value: string

	possibilityValue(numSection, state) : TriggerPossibility {
		var tmp = new TriggerPossibility({
			title: `${this._title.fr} ${state}`,
			value: `{{section[${numSection}].infos[${this.name}].${state}}}`,
			sign:[],
			possibility:[]
		})
		if (this.type == "list") {
			tmp.sign = ["==", "!="]
			tmp.possibility = this.parameters._list.map((x) => {
				return new TriggerPossibilityValue({title: x.fr, value: `${x.id}`})
			})
		} else if (this.type == "number" || this.type == "date") {
			tmp.sign = ["==", "!=", ">", ">=", "<", "<=", "IN", "NOT IN"]
		} else {
			tmp.sign = ["==", "!=", "CONTAINE", "NOT CONTAINE", "IN", "NOT IN"]
		} 
		return tmp
	}

	public constructor(init?:Partial<TemplatePart>) {
		Object.assign(this, init);
	} 
}

export class TemplateChecklists  {
	title: number
	_title: Translation

	name: string
	dropoff: boolean
	field_id:number
	required: boolean
	section:string
	type:string
	position:number
	part_id:number
	no_restart:boolean

	value: string

	public constructor(init?:Partial<TemplatePart>) {
		Object.assign(this, init);
	}

	possibilityValue(numSection, state) : TriggerPossibility {
		var tmp = new TriggerPossibility({
			title: `${this._title.fr} ${state}`,
			value: `{{section[${numSection}].checklists[${this.name}].${state}}}`,
			sign:[],
			possibility:[]
		})
		if (this.type == "boolean") {
			tmp.sign = ["==", "!="]
			tmp.possibility = [
			new TriggerPossibilityValue({title: "OUI / true", value: "true"}), 
			new TriggerPossibilityValue({title: "NON / false", value: "false"})
			]
		}  else if (this.type == "numeric") {
			tmp.sign = ["==", "!=", ">", ">=", "<", "<=", "IN", "NOT IN"]
		} 
		return tmp
	}
}
export class TemplateSupports {

	title: number
	_title: Translation

	name: string
	dropoff: boolean
	field_id:number
	required: boolean
	section:string
	type:string
	position:number
	part_id:number

	public constructor(init?:Partial<TemplatePart>) {
		Object.assign(this, init);
	}

	possibilityValue(numSection, state) : TriggerPossibility {
		return new TriggerPossibility({
			title: `${this._title.fr} ${state}`,
			value: `{{section[${numSection}].supports[${this.name}].${state}}}`,
			sign: ["==", "!="],
			possibility:[]
		})
	}
}