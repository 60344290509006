import { Component, OnInit, Input } from '@angular/core';
import { Report } from '../../../models';
import { ApiService } from '../../api/api.module';

let downloadFile = function (filename, data) {
	var link = document.createElement('a')

	let blob = data
	link.href = window.URL.createObjectURL(blob)
	link.download = filename

	document.body.appendChild(link)
	link.click()

	setTimeout(function(){
		document.body.removeChild(link)
		window.URL.revokeObjectURL(link.href)
	}, 100)
}

@Component({
	selector: '[app-mini-report]',
	templateUrl: './mini-report.component.html',
	styleUrls: ['./mini-report.component.scss']
})
export class MiniReportComponent implements OnInit {
	@Input() report  :Report;
	@Input('infos') infos = true;
	constructor(private api: ApiService) { }

	ngOnInit() {
	}

}
